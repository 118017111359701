import { useState, useRef, useEffect } from 'react';
import * as Sentry from '@sentry/react';

type StorageType = 'local' | 'session';

function initState<T>(defaultValue: T, stateKey: string, storageType: StorageType) {
  const localState = localStorage.getItem(stateKey);
  const sessionState = sessionStorage.getItem(stateKey);

  if (storageType === 'local' && localState) {
    return JSON.parse(localState) as T;
  }

  if (storageType === 'session' && sessionState) {
    return JSON.parse(sessionState) as T;
  }

  return defaultValue as T;
}

const useStorageState = <T>(
  stateKey: string,
  defaultValue: T,
  storageType: StorageType = 'local',
) => {
  const [state, setState] = useState<T>(() => initState(defaultValue, stateKey, storageType));
  const isNewSession = useRef(true);

  const storage = storageType === 'local' ? localStorage : sessionStorage;

  useEffect(() => {
    if (isNewSession.current) {
      const storageState = storage.getItem(stateKey);

      if (storageState) {
        setState(JSON.parse(storageState) as T);
      } else {
        setState(defaultValue);
      }

      isNewSession.current = false;
      return;
    }

    try {
      storage.setItem(stateKey, JSON.stringify(state));
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: `Error trying to set sessionStorage ${stateKey}, ${JSON.stringify(state)}`,
          file: '?',
          fn: 'useStorageState()-01',
        });
        Sentry.captureException(error);
      });
    }
  }, [state, stateKey, defaultValue, storage]);

  const updateState = (newState: T) => {
    setState(newState);
    storage.setItem(stateKey, JSON.stringify(newState));
  };

  const clearStorage = () => {
    storage.removeItem(stateKey);
  };

  return [state, updateState, clearStorage] as const;
};

export default useStorageState;
