/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-use-before-define */
import * as Sentry from '@sentry/react';
import { InsightsNav, SessionPlData } from '~/hooks/queries/useSessionPlQuery/types';
import { customFetch } from '~/utils/customFetch';
import { entries } from '~/utils/typescriptHelpers';

async function getClientSessionInfo(
  accountId?: number | string,
  accountType?: 'partner' | 'client',
) {
  let params = {} as Record<string, string>;
  if (accountId && accountType) {
    params = {
      [accountType === 'partner' ? 'partnerId' : 'clientId']: accountId.toString(),
    };
  }
  const result = await customFetch.get<SessionPlData>(`/session.pl`, { params });
  return result.data;
}

export const handleGetClientSessionInfo = async (
  sessionData: SessionPlData | { error: string } | null = null,
  {
    accountId,
    accountType,
  }: { accountId?: number | string; accountType?: 'partner' | 'client' } = {},
) => {
  let sessionInfo: SessionPlData;

  if (sessionData && !('error' in sessionData)) {
    sessionInfo = sessionData;
  } else {
    sessionInfo = await getClientSessionInfo(accountId, accountType);
  }

  if (!sessionInfo || 'error' in sessionInfo) {
    if (sessionInfo.error !== 'no session') {
      Sentry.withScope((scope) => {
        scope.setTags({
          message: 'no sessionInfo or sessionInfo.error from handleGetClientSessionInfo()',
          file: 'Admin.jsx',
          error: 'error' in sessionInfo ? sessionInfo?.error : '',
          fn: 'handleGetClientSessionInfo()-01',
        });
        Sentry.captureException(new Error('handleGetClientSessionInfo()'));
      });
    }
    throw new Error('no session');
  }

  if ('partner' in sessionInfo) {
    const data = {
      ...sessionInfo,
      accountType: 'partner',
      isPartner: true,
      nav: sessionInfo.nav_permissions,
      insights_nav: addPathToInsightsNav(sessionInfo.partner_insights_nav),
      permissions: {
        ...sessionInfo.partner_permissions,
        genie: !!sessionInfo.nav_permissions.stats,
        dianomi: !!sessionInfo?.login?.is_dianomi,
        allowContextfeedManager: !!sessionInfo?.partner_permissions?.allowContextfeedManager,
      },
      partner: {
        ...sessionInfo.partner,
      },
      client: sessionInfo.partner,
      id: sessionInfo.partner.id,
      name: sessionInfo.partner.name,
      isParent: !!sessionInfo.partner.has_active_child,
      isChild: sessionInfo.partner.parent_partner_id !== null,
    } as const;
    Sentry.setUser({
      username: data.login.username,
      partnerId: data.id,
    });
    return data;
  }
  const data = {
    ...sessionInfo,
    isPartner: false,
    accountType: 'client',
    nav: sessionInfo.nav_permissions,
    insights_nav: addPathToInsightsNav(sessionInfo.client_insights_nav),
    permissions: {
      ...sessionInfo.client_permissions,
      genie: !!sessionInfo.nav_permissions.stats,
      dianomi: !!sessionInfo?.login?.is_dianomi,
      displayPublisherNames: ['1', null].includes(
        sessionInfo.client_permissions.display_publisher_name,
      ),
      displayPublisherIds: ['1', null, '0'].includes(
        sessionInfo.client_permissions.display_publisher_name,
      ),
    },
    id: sessionInfo.client.id,
    name: sessionInfo.client.name,
    currency_pdf_name: sessionInfo.client.currency_pdf_name,
    isParent: !!sessionInfo.client.has_active_child,
    isChild: sessionInfo.client.parent_client_id !== null,
  } as const;

  Sentry.setUser({
    username: data.login.username,
    clientId: data.id,
  });
  return data;
};

const addPathToInsightsNav = (nav: InsightsNav) => {
  const newNav = entries(nav || {}).map(([oldId, insight]) => ({
    ...insight,
    path: insight.name
      .toLowerCase()
      .replace(/[^A-Za-z0-9 ]/g, '')
      .replace(/\s{2,}/g, ' ')
      .replace(/\s/g, '-'),
    oldId,
  }));
  return newNav;
};

export type SessionPlQueryReturnData = Awaited<ReturnType<typeof handleGetClientSessionInfo>>;
