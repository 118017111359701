/* eslint-disable camelcase */
import { useMutation } from '@tanstack/react-query';
import { customFetch } from '~/utils/customFetch';
import { z } from 'zod';
import useNotifications from '~/context/Notifications';

type usePostalCodeMutationProps = {
  data: string[];
};

const responseSchema = z.discriminatedUnion('success', [
  z.object({
    success: z.literal(1),
    body: z.object({
      valid: z.string().array(),
      invalid: z.string().array(),
    }),
  }),
  z.object({
    success: z.literal(0),
    error: z.string().optional(),
  }),
]);

export type ResponseSchema = z.infer<typeof responseSchema>;

export function usePostalCodeMutation() {
  const { setTimedNotification } = useNotifications();
  return useMutation({
    mutationKey: ['useAccountUserMutation'],
    mutationFn: ({ data }: usePostalCodeMutationProps) => {
      return customFetch<ResponseSchema>(`/authenticated/regions/validate-postal-codes`, {
        method: 'POST',
        body: JSON.stringify({ data }),
        useNodeEndpoint: true,
        headers: {
          'Content-Type': 'application/json',
        },
      });
    },
    onError: () =>
      setTimedNotification(
        'danger',
        "There's been an issue, please try again. If the issue persists, please contact us at mydianomi@dianomi.com",
        5000,
      ),
  });
}
