// React icons
import * as Sentry from '@sentry/react';
import { CancelledError, QueryClient } from '@tanstack/react-query';
import React from 'react';
import { Cookies } from 'react-cookie';
import {
  LoaderFunctionArgs,
  Navigate,
  RouterProvider as RRProvider,
  RouteObject,
  ShouldRevalidateFunction,
  createBrowserRouter,
  redirect,
} from 'react-router-dom';
import OnLoginClientsSelectorHandler from '~/components/Clients/OnLoginClientsSelector/OnLoginClientsSelector';
import AccountLayout from '~/layouts/Admin/Admin';
import HandleRedirects from '~/views/Dashboard/HandleRedirects';
import SessionHandler from '~/views/Dashboard/SessionHandler';
import ErrorPage from '~/views/Pages/ErrorPage';
import App from './App';
import RoutingHandler from './RoutingHandler';
import {
  adManagerDataQueryFn,
  adManagerQueryKeys,
} from './hooks/queries/useAdmanagerData/useAdManagerData';
import { getAudiencesQueryOptions } from './hooks/queries/useAudienceData';
import { getAudiencePreferenceQueryOptions } from './hooks/queries/useAudiencePreferenceQuery/useAudiencePreferenceQuery';
import { getClientPlQueryOptions } from './hooks/queries/useClientPlQuery';
import { getCohortsQueryOptions } from './hooks/queries/useCohortQuery/useCohortQuery';
import { getGenieNavQueryOptions } from './hooks/queries/useGenieNavQuery';
import { countriesQueryOptions } from './hooks/queries/useGeoDataQuery';
import { getOsTargetsQueryOptions } from './hooks/queries/useOsTargetsQuery';
import { getPartnersQueryOptions } from './hooks/queries/usePartnerData/usePartnerData';
import { getSessionPlQueryKey } from './hooks/queries/useSessionPlQuery';
import lazy from './lazy';
import { globalClient } from './query-client';
import { perms } from './routing/perms';
import { SessionPlQueryReturnData, handleGetClientSessionInfo } from './services/ClientServiceTS';
import { customFetch } from './utils/customFetch';
import { getAccountDetailsFromURL } from './utils/utils';
import SetupMFAPage from './views/Dashboard/SetupMFAPage';
import { audiencePreferencesToggleStorageKey } from './views/Dashboard/ad-form/cohort-targeting/cohorts.utils';
import {
  getCampaignGoalOptionsQueryOptions,
  getCampaignPlanByIdQueryOptions,
  getCampaignPlansQueryOptions,
} from './views/Dashboard/campaign-planner/hooks';
import { partnersWithLogoQueryOptions } from './views/Dashboard/supply-manager/usePartnersWithLogoQuery';
import ErrorBoundaryPage from './views/Pages/ErrorBoundaryPage';
import MfaPage from './views/Pages/MFAPage';
import NoSessionPage from './views/Pages/NoSessionPage';
import JoeTest from './views/Staff/JoeTest';

const PreviewsPage = lazy(() => import('./views/Pages/preview'));
const VerificationPreviewPage = lazy(() => import('./views/Pages/verification-preview'));
const RepricingVoiding = lazy(() => import('~/views/Staff/RepricingVoiding'));
const RepricingVoidingStatus = lazy(() => import('~/views/Staff/RepricingVoidingStatus'));
const VoidRepriceForm = lazy(() => import('~/views/Staff/VoidingRepricingForm/VoidRepriceForm'));
const VoidingRepricingManager = lazy(
  () => import('~/views/Staff/VoidingRepricingManager/VoidingRepricingManager'),
);
const EditingAdFormHandler = lazy(() => import('~/views/Dashboard/ad-form/editing-adform'));
const CreatingAdFormHandler = lazy(() => import('~/views/Dashboard/ad-form/creating-adform'));
const NewClient = lazy(() => import('~/views/Dashboard/NewClient'));
const AdManager = lazy(() => import('~/views/Dashboard/AdManager'));
const PubBidManager = lazy(() => import('~/views/Dashboard/PubBidManager/pub-bid-manager'));
const Insights = lazy(() => import('~/views/Dashboard/Insights'));
const Billing = lazy(() => import('~/views/Dashboard/Billing/Billing'));
const InvoiceRoute = lazy(() => import('~/views/Dashboard/Billing/Invoice'));
const PublisherAds = lazy(() => import('~/views/Dashboard/PublisherAds'));
const PublisherPaymentHistory = lazy(() => import('~/views/Dashboard/PublisherPaymentHistory'));
const AccountUsers = lazy(() => import('~/views/Dashboard/Account/AccountUsers'));
const AccountSettings = lazy(() => import('~/views/Dashboard/Account/AccountSettings'));
const UserProfile = lazy(() => import('~/views/Dashboard/UserProfile'));
const ConversionTrackers = lazy(
  () => import('~/views/Dashboard/ConversionTrackers/ConversionTrackerManager'),
);
const ArchivedAccountsPage = lazy(() => import('~/views/Pages/ArchivedAccountsPage'));
const Analytics = lazy(() => import('~/views/Dashboard/Analytics'));
const ForbiddenPage = lazy(() => import('~/views/Pages/ForbiddenPage'));
const OverviewHandler = lazy(() => import('~/views/Dashboard/Overview/OverviewHandler'));
const DeviceBidManager = lazy(() => import('~/views/Dashboard/DeviceBidManager'));
const AccountParameters = lazy(
  () => import('~/views/Dashboard/AccountParameters/AccountParameters'),
);
const AccountLevelRules = lazy(
  () => import('~/views/Dashboard/AccountParameters/AccountLevelRules'),
);
const StaffLayout = lazy(() => import('./layouts/Staff/StaffLayout'));
const InviteClient = lazy(() => import('./views/Staff/InviteClient/InviteClient'));
const AuthLayout = lazy(() => import('./layouts/Auth/Auth'));
const LoginPage = lazy(() => import('./views/Pages/LoginPage'));
const SetPassword = lazy(() => import('./views/Pages/SetPassword'));
const Invite = lazy(() => import('./views/Pages/Signup/Invite'));
const UserFormHandler = lazy(() => import('./views/Pages/Signup/UserForm'));
const SignUpCompletePage = lazy(() => import('./views/Pages/Signup/SignUpCompletePage'));
const StepLayout = lazy(() => import('./views/Pages/Signup/StepLayout'));
const AutomatedReporting = lazy(() => import('./views/Dashboard/AutomatedReporting'));
const AutoReportFormWrapper = lazy(
  () => import('./views/Dashboard/AutomatedReporting/AutoReportFormWrapper'),
);
const StaffDashboard = lazy(() => import('./views/Staff/StaffDashboard'));
const UploadSignupIOWrapper = lazy(() => import('./views/Staff/UploadSignupIO/UploadSignupIO'));
const Legal = lazy(() => import('./views/Legal/Legal'));
const LegalType = lazy(() => import('./views/Legal/LegalType'));
const ManageInvites = lazy(() => import('./views/Staff/ManageInvites/ManageInvites'));
const ClientDetails = lazy(() => import('./views/Pages/Signup/ClientForm'));
const BillingDetails = lazy(() => import('./views/Pages/Signup/BillingForm'));
const ReviewStep = lazy(() => import('./views/Pages/Signup/ReviewStep'));
const AudienceManager = lazy(() => import('./views/Dashboard/Audience/audience-manager'));
const SupplyManager = lazy(() => import('./views/Dashboard/supply-manager'));
const ContextfeedManager = lazy(() => import('./views/Dashboard/Contextfeed/contextfeed-manager'));
const MFARecoveryPage = lazy(() => import('./views/Pages/MFARecovery'));
const CampaignPlanner = lazy(() => import('./views/Dashboard/campaign-planner/campaign-planner'));

/**
 * If you remove this anywhere in the routes array below,
 * you will need to handle permissions yourself
 * in your loader functions or components
 */
const defaultPermsLoader = (perm: (typeof perms)[keyof typeof perms], queryClient: QueryClient) => {
  return async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { accountType, accountId } = getAccountDetailsFromURL(url.pathname) ?? {};
    const isPartner = accountType === 'partner';
    let newSessionData = {} as SessionPlQueryReturnData;
    const oldSessionData = queryClient.getQueryData<SessionPlQueryReturnData>(
      getSessionPlQueryKey({
        accountId,
        accountType,
      }),
    );
    const noSessionReferer =
      url.pathname !== '/no-session'
        ? new URLSearchParams({ referer: url.pathname + url.search }).toString()
        : '';

    const hasUrlAccountDetails = !!accountType && !!accountId;
    // when there's no url account details, we don't need to check if the session data is different to the url... obviously
    if (oldSessionData && !hasUrlAccountDetails) {
      newSessionData = oldSessionData;
    } else if (
      // ensure sessionData and url account details match
      oldSessionData &&
      oldSessionData.id.toString() === accountId &&
      oldSessionData.isPartner === isPartner
    ) {
      newSessionData = oldSessionData;
    } else {
      // either we don't have oldSessionData or url and account details don't match session data, so we need to get new session data
      try {
        newSessionData = await handleGetClientSessionInfo(null, { accountId, accountType });
        queryClient.clear();
        queryClient.setQueryData(
          getSessionPlQueryKey({
            accountId: String(newSessionData.id),
            accountType: newSessionData.accountType,
          }),
          newSessionData,
        );
      } catch (e) {
        console.error(e);
        if (e instanceof CancelledError) {
          return null;
        }
        throw redirect(`/no-session?${noSessionReferer}`);
      }
    }

    if (!newSessionData) {
      throw redirect(`/no-session?${noSessionReferer}`);
    }
    if (perm({ sessionPlData: newSessionData })) {
      return newSessionData;
    }
    throw redirect(`/${accountType}/${accountId}/forbidden`);
  };
};

const analyticsLoader = async (ctx: LoaderFunctionArgs) => {
  const sessionData = await defaultPermsLoader(perms.analytics, globalClient)(ctx);
  const genieNavData = await globalClient.prefetchQuery(getGenieNavQueryOptions({ sessionData }));
  return { sessionData, genieNavData };
};

const ignoreSearchParams: ShouldRevalidateFunction = ({ currentUrl, nextUrl }) => {
  const currentPathname = currentUrl.pathname;
  const nextPathname = nextUrl.pathname;
  return currentPathname !== nextPathname;
};

const Routes: RouteObject[] = [
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorBoundaryPage />,
    children: [
      {
        index: true,
        element: <RoutingHandler />,
      },
      {
        path: '/:accountType/:accountId/previews/:publisherId?/:productId/:adgroupId/:variantId',
        // this component is using casting in params so if you change things
        // here things in the component _WILL_ break
        element: <PreviewsPage />,
        loader: defaultPermsLoader(perms.loggedIn, globalClient),
      },
      {
        path: '/:accountType/:accountId/previews/:productId/verification/:productPromotionId',
        // this component is using casting in params so if you change things
        // here things in the component _WILL_ break
        element: <VerificationPreviewPage />,
        loader: defaultPermsLoader(perms.loggedIn, globalClient),
      },
      {
        element: <AccountLayout />,
        children: [
          {
            path: 'select-account',
            element: <OnLoginClientsSelectorHandler />,
            loader: defaultPermsLoader(perms.loggedIn, globalClient),
          },
          {
            path: 'admin/*',
            element: <HandleRedirects />,
            loader: defaultPermsLoader(perms.loggedIn, globalClient),
          },
          {
            path: ':accountType/:accountId',
            element: <SessionHandler />,
            children: [
              {
                index: true,
                element: <Navigate to="dashboard" />,
              },
              {
                path: 'dashboard',
                loader: async (ctx) => {
                  await defaultPermsLoader(perms.overview, globalClient)(ctx);
                  const { accountType, accountId } = ctx.params;
                  if (accountType === 'client') {
                    // await it to ensure it's fetched before the
                    // overviewHandler is rendered so we don't make unnecessary
                    // calls to the wrong stats on load
                    await globalClient.prefetchQuery(getClientPlQueryOptions(Number(accountId)));
                  }
                  return null;
                },
                element: <OverviewHandler />,
              },
              {
                path: 'account',
                shouldRevalidate: ignoreSearchParams,
                children: [
                  {
                    index: true,
                    element: <Navigate to="parameters" />,
                  },
                  {
                    path: 'parameters',
                    element: <AccountParameters />,
                    loader: defaultPermsLoader(perms.accountParameters, globalClient),
                    shouldRevalidate: ignoreSearchParams,
                  },
                  {
                    path: 'rules',
                    element: <AccountLevelRules />,
                    loader: defaultPermsLoader(perms.accountRules, globalClient),
                  },
                  {
                    path: 'publisher-manager/bid',
                    children: [
                      {
                        index: true,
                        element: <PubBidManager />,
                        loader: defaultPermsLoader(perms.pubBidManagerClient, globalClient),
                      },
                      {
                        path: ':campaignId',
                        element: <PubBidManager />,
                        loader: defaultPermsLoader(perms.pubBidManagerProduct, globalClient),
                      },
                    ],
                  },
                  {
                    path: 'device-manager/bid',
                    children: [
                      {
                        index: true,
                        element: <DeviceBidManager />,
                        loader: defaultPermsLoader(perms.deviceBidManagerClient, globalClient),
                      },
                      {
                        path: ':campaignId',
                        element: <DeviceBidManager />,
                        loader: defaultPermsLoader(perms.deviceBidManagerProduct, globalClient),
                      },
                    ],
                  },
                  {
                    path: 'actions',
                    element: <ConversionTrackers />,
                    loader: defaultPermsLoader(perms.conversionTrackers, globalClient),
                  },
                  {
                    path: 'users',
                    element: <AccountUsers />,
                    loader: defaultPermsLoader(perms.accountUsers, globalClient),
                  },
                  {
                    path: 'profile',
                    element: <AccountSettings />,
                    loader: defaultPermsLoader(perms.accountSettings, globalClient),
                  },
                  {
                    path: 'automated-reporting',
                    loader: analyticsLoader,
                    children: [
                      {
                        index: true,
                        element: <AutomatedReporting />,
                        loader: analyticsLoader,
                      },
                      {
                        path: ':autoReportId',
                        element: <AutoReportFormWrapper />,
                        loader: analyticsLoader,
                      },
                      {
                        path: 'new',
                        element: <AutoReportFormWrapper />,
                        loader: analyticsLoader,
                      },
                    ],
                  },
                  {
                    path: 'audience-manager',
                    element: <AudienceManager />,
                    loader: async (ctx) => {
                      await defaultPermsLoader(perms.audienceManager, globalClient)(ctx);
                      globalClient.prefetchQuery(
                        getCohortsQueryOptions(Number(ctx.params.accountId)),
                      );
                      globalClient.prefetchQuery(
                        getAudiencePreferenceQueryOptions(Number(ctx.params.accountId)),
                      );
                      return null;
                    },
                  },
                  {
                    path: 'supply-manager',
                    element: <SupplyManager />,
                    loader: async (ctx) => {
                      await defaultPermsLoader(perms.supplyManager, globalClient)(ctx);
                      if (!ctx.params.accountId) {
                        return null;
                      }
                      globalClient.prefetchQuery(
                        partnersWithLogoQueryOptions({ accountId: Number(ctx.params.accountId) }),
                      );
                      return null;
                    },
                  },
                  {
                    path: 'campaign-planner',
                    element: <CampaignPlanner />,
                    loader: async (ctx) => {
                      await defaultPermsLoader(perms.loggedIn, globalClient)(ctx);
                      globalClient.prefetchQuery(
                        getCohortsQueryOptions(Number(ctx.params.accountId)),
                      );
                      globalClient.prefetchQuery(countriesQueryOptions);
                      globalClient.prefetchQuery(
                        getCampaignGoalOptionsQueryOptions(Number(ctx.params.accountId)),
                      );
                      const plans = await globalClient.ensureQueryData(
                        getCampaignPlansQueryOptions(Number(ctx.params.accountId)),
                      );
                      if (plans) {
                        for (const plan of plans) {
                          globalClient.setQueriesData(
                            getCampaignPlanByIdQueryOptions(
                              plan.externalId,
                              Number(ctx.params.accountId),
                            ),
                            () => plan,
                          );
                        }
                      }
                      return null;
                    },
                    handle: {
                      lazyChildren: () =>
                        import('~/views/Dashboard/campaign-planner/routes').then((m) => m.children),
                    },
                  },
                ],
              },
              // Routes specifically for partners which sit in a different position to clients
              {
                path: 'automated-reporting',
                loader: analyticsLoader,
                children: [
                  {
                    index: true,
                    element: <AutomatedReporting />,
                    loader: analyticsLoader,
                  },
                  {
                    path: ':autoReportId',
                    element: <AutoReportFormWrapper />,
                    loader: analyticsLoader,
                  },
                  {
                    path: 'new',
                    element: <AutoReportFormWrapper />,
                    loader: analyticsLoader,
                  },
                ],
              },
              {
                path: 'users',
                element: <AccountUsers />,
                loader: analyticsLoader,
              },
              {
                path: 'contextfeed-manager',
                element: <ContextfeedManager />,
                loader: async (ctx) => {
                  const [loader] = await Promise.all([
                    import('./views/Dashboard/Contextfeed/manager-loader').then(
                      (route) => route.contextfeedManagerLoader,
                    ),
                    defaultPermsLoader(perms.contextfeedManager, globalClient)(ctx),
                  ]);

                  return loader(ctx, globalClient);
                },
                shouldRevalidate: ignoreSearchParams,
              },
              // Duplicate Partner routes end here
              {
                path: 'ad-manager',
                children: [
                  {
                    index: true,
                    element: <AdManager />,
                    loader: async (ctx) => {
                      if (!ctx.params.accountId) {
                        return null;
                      }
                      await defaultPermsLoader(perms.adManager, globalClient)(ctx);
                      // prefill cache with lots of data
                      globalClient.prefetchQuery({
                        queryKey: adManagerQueryKeys.all({ clientId: ctx.params.accountId }),
                        queryFn: () => adManagerDataQueryFn(Number(ctx.params.accountId)),
                      });
                      globalClient.prefetchQuery(getPartnersQueryOptions());
                      globalClient.prefetchQuery(
                        getCohortsQueryOptions(Number(ctx.params.accountId)),
                      );
                      globalClient.prefetchQuery(getOsTargetsQueryOptions());
                      globalClient.prefetchQuery(getAudiencesQueryOptions());

                      return null;
                    },
                    shouldRevalidate: ignoreSearchParams,
                  },
                  {
                    path: ':campaignId',
                    children: [
                      {
                        path: 'edit',
                        element: <EditingAdFormHandler />,
                        loader: async (ctx) => {
                          if (!ctx.params.accountId) {
                            return null;
                          }
                          if (!ctx.params.campaignId) {
                            throw redirect(
                              `/${ctx.params.accountType}/${ctx.params.accountId}/ad-manager`,
                            );
                          }
                          await defaultPermsLoader(perms.adManager, globalClient)(ctx);
                          // prefill cache with lots of data
                          globalClient.prefetchQuery({
                            queryKey: adManagerQueryKeys.single({
                              clientId: ctx.params.accountId,
                              productId: Number(ctx.params.campaignId),
                            }),
                            queryFn: () =>
                              adManagerDataQueryFn(
                                Number(ctx.params.accountId),
                                Number(ctx.params.campaignId),
                              ),
                          });
                          globalClient.prefetchQuery(getPartnersQueryOptions());
                          const starredOnly =
                            Boolean(localStorage.getItem(audiencePreferencesToggleStorageKey)) ??
                            false;
                          globalClient.prefetchQuery(
                            getCohortsQueryOptions(Number(ctx.params.accountId), starredOnly),
                          );
                          globalClient.prefetchQuery(getOsTargetsQueryOptions());
                          globalClient.prefetchQuery(getAudiencesQueryOptions());

                          return null;
                        },
                        shouldRevalidate: ignoreSearchParams,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'new-ad',
                element: <Navigate to="native" />,
              },
              {
                path: 'new-ad/:type',
                element: <CreatingAdFormHandler />,
                loader: async (ctx) => {
                  const starredOnly =
                    Boolean(localStorage.getItem(audiencePreferencesToggleStorageKey)) ?? false;
                  if (!ctx.params.accountId) {
                    return null;
                  }
                  await defaultPermsLoader(perms.adManager, globalClient)(ctx);
                  // prefill cache with lots of data
                  globalClient.prefetchQuery(getPartnersQueryOptions());
                  globalClient.prefetchQuery(
                    getCohortsQueryOptions(Number(ctx.params.accountId), starredOnly),
                  );
                  globalClient.prefetchQuery(getOsTargetsQueryOptions());
                  globalClient.prefetchQuery(getAudiencesQueryOptions());
                  globalClient.prefetchQuery(
                    getAudiencePreferenceQueryOptions(Number(ctx.params.accountId)),
                  );

                  return null;
                },
                shouldRevalidate: ignoreSearchParams,
              },
              {
                path: 'my-leads',
                element: <Analytics />,
                loader: analyticsLoader,
              },
              {
                path: 'analytics/:filter',
                children: [
                  {
                    index: true,
                    element: <Analytics />,
                    loader: analyticsLoader,
                  },
                  {
                    path: ':linkId',
                    element: <Analytics />,
                    loader: analyticsLoader,
                  },
                ],
              },
              {
                path: 'insights/:insightPath',
                element: <Insights />,
                loader: defaultPermsLoader(perms.insights, globalClient),
              },
              {
                path: 'billing',
                children: [
                  {
                    index: true,
                    element: <Billing />,
                    loader: defaultPermsLoader(perms.billing, globalClient),
                  },
                  {
                    path: ':invoiceId',
                    element: <InvoiceRoute />,
                    loader: defaultPermsLoader(perms.invoiceRoute, globalClient),
                  },
                ],
              },
              {
                path: 'publisher-payment-history',
                element: <PublisherPaymentHistory />,
                loader: defaultPermsLoader(perms.publisherPaymentHistory, globalClient),
              },
              {
                path: 'publisher-ads',
                element: <PublisherAds />,
                loader: defaultPermsLoader(perms.publisherAds, globalClient),
              },
              {
                path: 'new-client',
                element: <NewClient />,
                loader: defaultPermsLoader(perms.newClient, globalClient),
              },
              {
                path: 'forbidden',
                element: <ForbiddenPage />,
              },
              {
                path: 'accounts-archived',
                element: <ArchivedAccountsPage />,
              },
              {
                path: '*',
                element: <ErrorPage />,
              },
            ],
          },
          {
            path: 'user-profile',
            element: <UserProfile />,
          },
          {
            path: '*',
            element: <ErrorPage />,
          },
        ],
      },
      {
        path: 'staff',
        element: <StaffLayout />,
        loader: defaultPermsLoader(perms.isDianomi, globalClient),
        children: [
          { index: true, element: <Navigate to="home" /> },
          {
            path: 'home',
            element: <StaffDashboard />,
          },
          {
            path: 'repricingVoidingStatus/:jobId',
            element: <RepricingVoidingStatus />,
          },
          {
            path: 'repricingvoiding',
            element: <RepricingVoiding />,
          },
          {
            path: 'void-reprice-form',
            element: <VoidRepriceForm />,
            loader: defaultPermsLoader(perms.isDianomi, globalClient),
          },
          {
            path: 'voiding-repricing-manager',
            element: <VoidingRepricingManager />,
            loader: defaultPermsLoader(perms.isDianomi, globalClient),
          },
          {
            path: 'select-account',
            element: <OnLoginClientsSelectorHandler />,
          },
          {
            path: 'client',
            children: [
              {
                index: true,
                element: <Navigate to="online-signup" />,
              },
              {
                path: 'online-signup',
                children: [
                  { index: true, element: <Navigate to="invite" /> },
                  {
                    path: 'invite-new-client',
                    element: <InviteClient />,
                  },
                  {
                    path: 'manage-invites',
                    element: <ManageInvites />,
                  },
                  {
                    path: 'upload-io',
                    element: <UploadSignupIOWrapper />,
                  },
                ],
              },
            ],
          },
          {
            path: 'joe-test',
            element: <JoeTest />,
          },
          {
            path: '*',
            element: <ErrorPage />,
          },
        ],
      },
      {
        path: '*',
        element: <ErrorPage />,
      },
      {
        element: <AuthLayout />,
        children: [
          { path: 'no-session', element: <NoSessionPage /> },

          {
            path: 'login',
            element: <LoginPage />,
          },
          {
            path: 'setup-mfa',
            element: <SetupMFAPage />,
          },
          {
            path: 'account-recovery',
            element: <MFARecoveryPage />,
          },
          {
            path: 'auth/email-mfa',
            element: <MfaPage />,
            loader: async (ctx) => {
              const url = new URL(ctx.request.url);

              const token = url.searchParams.get('token');
              if (!token) {
                return {
                  message: 'No token provided',
                };
              }
              const res = await customFetch.post<
                { success: true } | { success: false; message: string }
              >('/auth/email-mfa', JSON.stringify({ token }), {
                useNodeEndpoint: true,
                throwOnHTTPError: false,
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              const referer = url.searchParams.get('referer');

              if (res.status === 200 || res.data.success) {
                //
                const cookies = new Cookies();
                cookies.set('SESSION_STARTED', 1, { path: '/', secure: true });
                throw redirect(`/${referer ? `?referer=${referer}` : ''}`);
              }

              return {
                message: res.data.message,
              };
            },
          },
          {
            path: 'forgot-password',
            element: <SetPassword />,
          },
          {
            path: 'set-password',
            element: <SetPassword />,
          },
          {
            path: '*',
            element: <ErrorPage />,
          },
          {
            path: 'legal',
            element: <Legal />,
            children: [
              {
                index: true,
                element: <Navigate to="marketing-terms/us" replace />,
              },
              {
                path: 'marketing-terms',
                element: <Navigate to="us" replace />,
              },
              {
                path: ':legalType',
                element: <LegalType />,
              },
              {
                path: ':legalType/:region',
                element: <LegalType />,
              },
            ],
          },
          {
            path: 'invite/',
            children: [
              {
                index: true,
                element: <Navigate to="invalid-invite" />,
              },
              {
                path: ':inviteId',
                element: <Invite />,
                children: [
                  {
                    path: 'step',
                    element: <StepLayout />,
                    children: [
                      {
                        index: true,
                        element: <Navigate to="user" />,
                      },
                      {
                        path: 'user',
                        element: <UserFormHandler />,
                      },
                      {
                        path: 'company-details',
                        element: <ClientDetails />,
                      },
                      {
                        path: 'billing-details',
                        element: <BillingDetails />,
                      },
                      {
                        path: 'confirm',
                        element: <ReviewStep />,
                      },
                      {
                        path: '*',
                        element: <ErrorPage />,
                      },
                    ],
                  },
                  {
                    path: 'finish',
                    element: <SignUpCompletePage />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage />,
  },
];

type CreateBrowserRouter = typeof createBrowserRouter;

type RemixRouter = ReturnType<CreateBrowserRouter>;

type CreateBrowserRouterOptions = Parameters<CreateBrowserRouter>[1];

// augmenting sentry types as they don't support the opts arg properly (they type is as any)
declare module '@sentry/react' {
  export function wrapCreateBrowserRouterV6(
    fn: CreateBrowserRouter,
  ): (routes: RouteObject[], opts?: CreateBrowserRouterOptions) => RemixRouter;
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV6(createBrowserRouter);

const router = sentryCreateBrowserRouter(Routes, {
  async patchRoutesOnNavigation({ matches, patch }) {
    const leafRoute = matches[matches.length - 1].route;
    if (leafRoute?.handle?.lazyChildren) {
      const lazyChildren = await leafRoute.handle.lazyChildren();
      patch(leafRoute.id ?? null, lazyChildren);
    }
  },
});

export const RouterProvider = () => <RRProvider router={router} />;
