import AssignmentIcon from '@mui/icons-material/Assignment';
import { GenieNavPlData } from './hooks/queries/useGenieNavQuery';
import { perms } from './routing/perms';
import { SessionPlQueryReturnData } from './services/ClientServiceTS';
import { analyticsNav } from './services/analyticsNav';
import { insightsNav } from './services/insightsNav';

// How to build routes:
//  path: path after /accountType/accountId
// name: to go in side bar if needed
// icon: icon to show in side bar
// perm: perm from perms object
// live: set to false to disable the route
// paramsToKeep: [] - an array of the query params to keep in the url across a given route (e.g. to/from/campaignId)
// collapse: set to true to show in the sidebar as a collapsable item
// views: if collapse, set to the routes of the collapse

// add perm to perms object below (e.g. { permName: permission})
//  permission: either
//    - an entry on "client/partner permissions"
//    - or "client/partner nav" fields from session.pl endpoint
//    - or client id (int)
//    - or object (can be nested) in same format as received from session.pl endpoint
//       e.g. perm: { publisher_manager: { bid: 'client' }}
//    -  you can include multiple options when applying permissions as an object, you will simply need to add a pipe between the options
//       e.g. perm: { device_manager: {bid: 'client|all' }}
//       the above means permissions 'client' OR 'all' will provide access to the corresponding route,
//       these options are manipulated in Admin.utils.js (refer to processPermissionValues function)
//    You can use an array for multiple AND checks

const sidebarRoutes = [
  {
    path: '/dashboard',
    name: 'Overview',
    icon: 'Overview',
    perm: perms.overview,
    live: true,
    paramsToKeep: ['from', 'to', 'period', 'campaign'],
  },
  {
    path: '/ad-manager',
    name: 'Ad Manager',
    icon: 'AdManager',
    perm: perms.adManager,
    live: true,
  },
  {
    path: '/contextfeed-manager',
    name: 'Contextfeed Manager',
    icon: 'AdManager',
    perm: perms.contextfeedManager,
    live: true,
  },
  {
    collapse: true,
    path: 'new-ad/:type',
    name: 'New Ad',
    icon: 'NewAd',
    perm: perms.adForm,
    live: true,
    views: [
      {
        path: '/new-ad/native',
        name: 'New Native Ad',
        perm: perms.adForm,
        live: true,
      },
      {
        path: '/new-ad/video',
        name: 'New Video Ad',
        perm: perms.adForm,
        live: true,
      },
      {
        path: '/new-ad/display',
        name: 'New Display Ad',
        perm: perms.adForm,
        live: true,
      },
      {
        path: '/new-ad/podcast',
        name: 'New Podcast Ad',
        perm: perms.adForm,
        live: true,
      },
      {
        path: '/new-ad/canvas',
        name: 'New Canvas Ad',
        perm: perms.adForm,
        live: true,
      },
    ],
  },
  {
    path: '/my-leads',
    name: 'My Leads',
    icon: 'TickBox',
    paramsToKeep: ['from', 'to', 'period', 'campaign'],
    perm: perms.leads,
    live: true,
  },
  {
    collapse: true,
    path: '/analytics/:filter',
    name: 'Analytics',
    icon: 'Analytics',
    exact: true,
    state: 'analyticsCollapse',
    perm: perms.analytics,
    live: true,
    paramsToKeep: ['from', 'to', 'period', 'campaign'],
    viewsProp: 'analytics' as const,
    views(analytics: GenieNavPlData) {
      return analyticsNav(analytics);
    },
  },
  {
    collapse: true,
    path: '/insights/:insightPath',
    name: 'Insights',
    icon: 'Insights',
    state: 'insightsCollapse',
    perm: perms.insights,
    paramsToKeep: ['timeframe', 'country'],
    live: true,
    viewsProp: 'insightsNav' as const,
    views(insightsRoutes: SessionPlQueryReturnData['insights_nav']) {
      return insightsNav(insightsRoutes);
    },
  },
  {
    path: '/billing',
    name: 'Billing',
    icon: 'Billing',
    perm: perms.billing,
    live: true,
  },
  {
    path: '/publisher-payment-history',
    name: 'Payment History',
    icon: 'CaseStudy',
    perm: perms.publisherPaymentHistory,
    accountType: 'partner',
    live: true,
  },
  {
    path: '/publisher-ads',
    name: 'Ads',
    icon: AssignmentIcon,
    perm: perms.publisherAds,
    live: true,
  },
  {
    collapse: true,
    path: '/account',
    name: 'Account',
    icon: 'Account',
    state: 'settingsCollapse',
    perm: perms.accountUsers,
    live: true,
    views: [
      {
        path: '/account/parameters',
        name: 'Bookings Manager',
        live: true,
        perm: perms.accountParameters,
      },
      {
        path: '/account/audience-manager',
        name: 'Audience Manager',
        perm: perms.audienceManager,
        live: true,
      },
      {
        path: '/account/users',
        name: 'Users',
        perm: perms.accountUsers,
        live: true,
      },
      {
        path: '/account/profile',
        name: 'Settings',

        perm: perms.accountSettings,
        live: true,
      },
      { path: '/account/rules', name: 'Rules', live: true, perm: perms.accountRules },
      {
        path: '/account/publisher-manager/bid',
        name: 'Publisher Bidding',
        perm: perms.pubBidManagerClient,
        live: true,
      },
      {
        path: '/account/device-manager/bid',
        name: 'Device Bidding',
        perm: perms.deviceBidManagerClient,
        live: true,
      },
      {
        path: '/account/automated-reporting',
        name: 'Automated Reporting',
        perm: perms.analytics,
        live: true,
        accountType: 'client',
      },
      {
        path: '/account/actions',
        name: 'Conversion Trackers',
        perm: perms.conversionTrackers,
        live: true,
      },
      {
        path: '/account/supply-manager',
        name: 'Supply Manager',
        perm: perms.supplyManager,
        live: true,
      },
      {
        path: '/account/campaign-planner',
        name: 'Campaign Planner',
        perm: perms.campaignPlanner,
        live: true,
      },
    ]
      .filter(Boolean)
      .sort((a, b) => a.name.localeCompare(b.name)),
  },
  {
    path: '/automated-reporting',
    name: 'Automated Reporting',
    perm: perms.analytics,
    live: true,
    accountType: 'partner',
    icon: 'AutomatedReporting',
  },
  {
    path: '/users',
    name: 'Users',
    perm: perms.analytics,
    accountType: 'partner',
    live: true,
    icon: 'Account',
  },
  {
    path: 'https://help.dianomi.com',
    name: 'Help Center',
    icon: 'CaseStudy',
    live: true,
    external: true,
    perm: () => true,
  },
];
export default sidebarRoutes;
