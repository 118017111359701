/* eslint-disable @tanstack/query/prefer-query-object-syntax */
import { capitalize } from '@mui/material';
import {
  keepPreviousData,
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { z } from 'zod';
import { useClientSessionQuery } from '../../../hooks/queries/useSessionPlQuery';
import { customFetch } from '../../../utils/customFetch';
import { campaignPlannerSchema, goalsSchema } from './store';
import { CampaignType } from './utils';

type Allocation = {
  type: string;
  percentage: number;
  spend?: number;
};

type GoalsResponseData = {
  allocations: { type: string; percentage: number }[];
};

function percentageToSpend(percentage: number, budget: number) {
  // Round to 2 decimal places for currency values
  return Math.round((percentage / 100) * budget * 100) / 100;
}

function processAllocations(
  allocations: GoalsResponseData['allocations'],
  budget?: number | null,
): Allocation[] {
  const hasValidBudget = budget != null;

  const processedAllocations = allocations.map((allocation): Allocation => {
    if (hasValidBudget && budget) {
      return {
        ...allocation,
        spend: percentageToSpend(allocation.percentage, budget),
        type: capitalize(allocation.type),
      };
    }
    return {
      ...allocation,
      type: capitalize(allocation.type),
    };
  });

  // If we're dealing with budget values, ensure they sum to exactly the total budget
  if (hasValidBudget) {
    const total = processedAllocations.reduce((sum, item) => sum + (item.spend ?? 0), 0);
    const diff = budget - total;
    if (diff !== 0) {
      // Add any rounding difference to the largest allocation
      const largestAllocation = processedAllocations.reduce(
        (max, item) => ((item.spend ?? 0) > (max.spend ?? 0) ? item : max),
        processedAllocations[0],
      );
      if (largestAllocation.spend !== undefined) {
        largestAllocation.spend = Math.round((largestAllocation.spend + diff) * 100) / 100;
      }
    }
  }

  return processedAllocations;
}

export const goalsQueryOptions = (data: z.infer<typeof goalsSchema> & { budget?: number | null }) =>
  queryOptions({
    queryKey: ['campaign-goals', data],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const params = new URLSearchParams();
      params.set('primaryGoal', data.primaryGoal);
      params.set('secondaryGoal', data.secondaryGoal);
      for (const campaignType of data.campaignTypes) {
        params.append('campaignTypes', campaignType);
      }
      const response = await customFetch.get<GoalsResponseData>(
        `/authenticated/client/3326/campaign-planner/goals`,
        {
          params,
          headers: {
            'Content-Type': 'application/json',
          },
          useNodeEndpoint: true,
        },
      );

      const rawData = response.data;
      return {
        raw: rawData,
        processed: {
          allocations: processAllocations(rawData.allocations, data.budget),
        },
      };
    },
    enabled: !!data.primaryGoal && !!data.secondaryGoal && !!data.campaignTypes.length,
  });

export function useGoalsQuery(data: z.infer<typeof goalsSchema> & { budget?: number | null }) {
  return useQuery(goalsQueryOptions(data));
}

export type CampaignPlannerMetricsResponseData = {
  allocatedBudget: number;
  budgetPercentage: number;
  estimatedClicks: number;
  estimatedImpressions: number;
  estimatedVideo2S: number;
  estimatedViewableImpressions: number;
  estimatedPodcastEngagements: number;
  type: CampaignType;
  estimatedCPC: number;
  estimatedCPM: number;
  estimatedVCPM: number;
  estimatedVideoVCPM: number;
  estimatedCPE: number;
};

export const metricsQueryOptions = (data: z.infer<typeof campaignPlannerSchema>) =>
  queryOptions({
    queryKey: ['campaign-metrics', data],
    placeholderData: keepPreviousData,
    queryFn: async () => {
      const params = new URLSearchParams();
      params.set('primaryGoal', data.primaryGoal);
      params.set('secondaryGoal', data.secondaryGoal);
      for (const campaignType of data.campaignTypes) {
        params.append('campaignTypes', campaignType);
      }
      params.set('budget', data.budget?.toString() ?? '');
      params.set('durationDays', data.durationDays?.toString() ?? '');

      params.set('productTypeId', data.productTypeId?.toString() ?? '');
      for (const geoId of data.geoIds) {
        // skip everywhere
        if (geoId !== 722) {
          params.append('geoIds', geoId.toString());
        }
      }

      const response = await customFetch.get<CampaignPlannerMetricsResponseData[]>(
        `/authenticated/client/3326/campaign-planner/metrics`,
        {
          params,
          headers: {
            'Content-Type': 'application/json',
          },
          useNodeEndpoint: true,
        },
      );

      return response.data;
    },
    enabled: !!data.productTypeId,
  });

export function useMetricsQuery(data: z.infer<typeof campaignPlannerSchema>) {
  return useQuery(metricsQueryOptions(data));
}

function recalculateBudgetPercentage(lineItems: CampaignPlannerMetricsResponseData[]) {
  const totalBudget = lineItems.reduce((sum, item) => sum + item.allocatedBudget, 0);
  return lineItems.map((item) => ({
    ...item,
    budgetPercentage: (item.allocatedBudget / totalBudget) * 100,
  }));
}
export type SavedCampaignPlan = z.infer<typeof campaignPlannerSchema> & {
  lineItems: CampaignPlannerMetricsResponseData[];
  externalId: string;
  name: string;
  createdAt: string;
  updatedAt: string;
};

export const getCampaignPlansQueryOptions = (accountId?: number | string) => {
  return queryOptions({
    queryKey: ['campaign-plans', String(accountId)],
    queryFn: async () => {
      const response = await customFetch.get<{ plans: SavedCampaignPlan[] }>(
        `/authenticated/client/${accountId}/campaign-planner/plans`,
        {
          useNodeEndpoint: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );
      // recalculate lineItems.budgetPercentage
      return response.data.plans.map((plan) => ({
        ...plan,
        lineItems: recalculateBudgetPercentage(plan.lineItems),
      }));
    },
    enabled: !!accountId,
  });
};

export const getCampaignPlanByIdQueryOptions = (planId?: string, accountId?: number | string) => {
  return queryOptions({
    queryKey: ['campaign-plans', String(accountId), planId],
    queryFn: async () => {
      const response = await customFetch.get<{ plan: SavedCampaignPlan }>(
        `/authenticated/client/${accountId}/campaign-planner/plans/${planId}`,
        {
          useNodeEndpoint: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      return {
        ...response.data.plan,
        lineItems: recalculateBudgetPercentage(response.data.plan.lineItems),
      };
    },
    enabled: !!accountId && !!planId,
    staleTime: Infinity,
  });
};

export function useCampaignPlansQuery() {
  const sessionPlQuery = useClientSessionQuery((state) => state.id);
  return useQuery(getCampaignPlansQueryOptions(sessionPlQuery.data));
}

export function useCampaignPlanByIdQuery(planId: string) {
  const sessionPlQuery = useClientSessionQuery((state) => state.id);
  return useQuery(getCampaignPlanByIdQueryOptions(planId, sessionPlQuery.data));
}

export function useSaveCampaignPlannerQuery() {
  return useMutation({
    mutationFn: async (data: {
      outputData: CampaignPlannerMetricsResponseData[];
      inputData: z.infer<typeof campaignPlannerSchema>;
      name: string;
    }) => {
      const response = await customFetch.post<{
        success: boolean;
        planId: string;
      }>('/authenticated/client/3326/campaign-planner/save', JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json',
        },
        useNodeEndpoint: true,
      });

      if (!response.data.success) {
        throw new Error('Failed to save campaign planner');
      }

      return response.data;
    },
  });
}

export function useDeleteCampaignPlannerQuery() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (planId: string) => {
      const response = await customFetch.delete(
        `/authenticated/client/3326/campaign-planner/plans/${planId}`,
        {
          useNodeEndpoint: true,
        },
      );

      return response.data;
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaign-plans'] });
    },
  });
}

export const getCampaignGoalOptionsQueryOptions = (accountId?: number | string) =>
  queryOptions({
    queryKey: ['campaign-goal-options', String(accountId)],
    queryFn: async () => {
      const response = await customFetch.get<{
        goalOptions: { id: string; name: string }[];
      }>(`/authenticated/client/${accountId}/campaign-planner/goal-options`, {
        useNodeEndpoint: true,
      });

      return response.data;
    },
    enabled: !!accountId,
  });

export function useCampaignGoalOptionsQuery() {
  const sessionPlQuery = useClientSessionQuery((state) => state.id);
  return useQuery(getCampaignGoalOptionsQueryOptions(sessionPlQuery.data));
}
