import { keepPreviousData, queryOptions, useQuery, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { customFetch } from '~/utils/customFetch';
import { ErrorObjectString } from '~/utils/typescriptHelpers';
import { processGeo } from '~/views/Dashboard/ad-form/geo-targeting/utils';

export const GeoEntityTypeSchema = z.union([
  z.literal('Country'),
  z.literal('State'),
  z.literal('Country Group'),
  z.literal('DMA'),
  z.literal('City'),
  z.literal('Postal Code'),
]);

export const GeoDataSchema = z.object({
  type: GeoEntityTypeSchema,
  id: z.number(),
  name: z.string(),
  extendedName: z.string(),
  hasChildren: z.boolean(),
  countryId: z.number(),
  countryGroupId: z.number().optional(),
  stateId: z.number(),
  state: z.string().optional(),
  city: z.string().optional(),
});

export const RawGeoDataSchema = z.array(GeoDataSchema);

export type RawGeoData = z.infer<typeof RawGeoDataSchema>;

type ResponseData = RawGeoData | ErrorObjectString;

const getProcessedData = ({
  data,
  isSearch,

  parents,
}: {
  data: RawGeoData;
  isSearch: boolean;

  parents?: number[];
}) =>
  processGeo(data, isSearch, parents).sort((a, b) => {
    if (a.id === 722) {
      return -1;
    }
    if (b.id === 722) {
      return 1;
    }

    if (isSearch) {
      return 1;
    }
    if (a.type === 'DMA' && b.type === 'DMA') {
      return a.name.localeCompare(b.name);
    }

    if (a.type === 'DMA') {
      return 1;
    }

    if (b.type === 'DMA') {
      return -1;
    }

    return a.name.localeCompare(b.name);
  });

export async function getGeoData(parentId?: number, searchValue?: string, parents?: number[]) {
  const isSearch = searchValue && searchValue.length > 2;

  let requestParams: { [x: string]: string } | undefined = parentId
    ? {
        queryId: parentId.toString(),
      }
    : undefined;

  if (isSearch && !parentId) {
    requestParams = { queryText: searchValue };
  }

  const requestOptions = {
    headers: {
      'content-header': 'application/json',
    },
    params: requestParams,
    useNodeEndpoint: true,
  };

  const apiEndpoint =
    !parentId && isSearch ? `/authenticated/regions/by-name` : `/authenticated/regions`;
  const { data: geoData } = await customFetch.get<ResponseData>(apiEndpoint, requestOptions);
  if ('error' in geoData) {
    throw new Error(geoData.error);
  }

  const processedGeoData = getProcessedData({
    data: geoData,
    isSearch: !!isSearch && !parentId,
    parents,
  });
  return processedGeoData;
}

export type GeoData = Awaited<ReturnType<typeof getGeoData>>;

function insertNewData(
  parentGeo: GeoData[number],
  oldData: GeoData,
  nextData: GeoData,
  queryId: number,
) {
  const parentCount = parentGeo.parents.length;
  // the children (nextData) will have one more parent than the parent (parentGeo)
  const childParentsCount = parentCount + 1;
  // filter out the geos that have more parents than the nextData (children) (ie cousins of the childData)
  const filteredData = oldData.filter(
    (geo) => geo.parents.length < childParentsCount || geo.isSearch,
  );
  const newParentIndex = filteredData.findIndex((geo) => geo.id === queryId);

  filteredData.splice(newParentIndex + 1, 0, ...nextData);

  return filteredData;
}

export function getGeoDataQueryKey({
  parentId = undefined,
  searchValue = '',
  parents = [],
}: {
  parentId?: number;
  searchValue?: string;
  parents?: number[];
} = {}) {
  return ['geoData', parentId, searchValue, parents] as const;
}

export const countriesQueryOptions = queryOptions({
  queryKey: ['geoData', 722],
  queryFn: async () => {
    const data = await getGeoData(722);
    return data;
  },
});

export function useCountriesQuery() {
  // eslint-disable-next-line @tanstack/query/prefer-query-object-syntax
  return useQuery(countriesQueryOptions);
}

function useGeoDataQuery({
  parentId,
  searchValue,
  parents,
}: { parentId?: number; searchValue?: string; parents?: number[] } = {}) {
  const queryClient = useQueryClient();

  const isSearch = searchValue && searchValue.length > 2;

  queryClient.setQueryDefaults(['geoData', 'internalStore'], {
    refetchOnWindowFocus: false,
    gcTime: Infinity,
    staleTime: Infinity,
    placeholderData: keepPreviousData,
  });

  return useQuery({
    queryKey: getGeoDataQueryKey({
      parentId,
      searchValue,
      parents,
    }),
    queryFn: async () => {
      const nextData = await getGeoData(parentId, searchValue, parents);
      if (!parentId) {
        queryClient.setQueryData(['geoData', 'internalStore'], () => nextData);
        return nextData;
      }

      const oldData = queryClient.getQueryData<GeoData>(['geoData', 'internalStore']);
      if (!oldData) {
        const countryData = queryClient.getQueryData<GeoData>(
          getGeoDataQueryKey({
            parents,
            parentId,
            searchValue,
          }),
        );
        const parentGeo = countryData?.find((geo) => geo.id === parentId);
        if (countryData && parentGeo) {
          const newData = insertNewData(parentGeo, countryData, nextData, parentId);
          queryClient.setQueryData(['geoData', 'internalStore'], () => newData);
          return newData;
        }
        return [];
      }

      const parentGeo = oldData.find((geo) => geo.id === parentId);
      if (!parentGeo) {
        // if no parent geo in search list, revert to original search query
        if (isSearch) {
          const originalSearchQuery = queryClient.getQueryData<GeoData>(
            getGeoDataQueryKey({
              parentId: undefined,
              searchValue,
              parents,
            }),
          );
          queryClient.setQueryData(['geoData', 'internalStore'], () => originalSearchQuery);

          return originalSearchQuery;
        }
        // at this point we've run out of options, so just return the data from the api
        queryClient.setQueryData(['geoData', 'internalStore'], () => nextData);
        return nextData;
      }

      const newData = insertNewData(parentGeo, oldData, nextData, parentId);
      queryClient.setQueryData(['geoData', 'internalStore'], () => newData);
      return newData;
    },
    refetchOnWindowFocus: false,
    gcTime: Infinity,
    staleTime: 0,
    // enabled,
    placeholderData: keepPreviousData,
  });
}

export default useGeoDataQuery;
