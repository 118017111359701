/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import {
  MaterialReactTable,
  MaterialReactTableProps,
  MRT_PaginationState,
  MRT_RowData,
  MRT_TableState,
  MRT_Updater,
} from 'material-react-table';
import * as React from 'react';
import TableCell from '~/views/Dashboard/Analytics/TableCell';

type OmittedProps<TData extends MRT_RowData> = Omit<
  Partial<MRT_TableState<TData>>,
  'isLoading' | 'showAlertBanner' | 'showProgressBars' | 'pagination'
>;

export interface CustomState<TData extends MRT_RowData> extends OmittedProps<TData> {
  isLoading?: boolean;
  isFetching?: boolean;
  isError?: boolean;
  pagination?: MRT_PaginationState;
}

export type BaseDataTableProps<TData extends MRT_RowData> = Prettify<
  Omit<MaterialReactTableProps<TData>, 'state' | 'table'>
>;

export default function DataTable<TData extends MRT_RowData>({
  data = [],
  columns,
  state,
  initialState,
  Actions = undefined,
  currency,
  formatNumbers = true,
  isDianomi = false,
  ...rest
}: Prettify<
  BaseDataTableProps<TData> & {
    state?: CustomState<TData>;
    Actions?: React.ReactNode;
    currency?: string;
    formatNumbers?: boolean;
    isDianomi?: boolean;
  }
>) {
  const [fullHeightTable, setFullHeightTable] = React.useState(false);
  const [pagination, setPagination] = React.useState(() => ({
    pageIndex: initialState?.pagination?.pageIndex ?? 0,
    pageSize: parseInt(localStorage.getItem('tablePageSize') ?? '100', 10),
  }));

  const handleFullHeightToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFullHeight = event.target.checked;
    const basePageSize = parseInt(localStorage.getItem('tablePageSize') ?? '100', 10) ?? 100;
    setFullHeightTable(!fullHeightTable);
    setPagination((prev) => ({
      ...prev,
      pageSize: newFullHeight && isDianomi ? data.length + 1 : basePageSize,
    }));
  };

  const onPaginationChange = React.useCallback((updater: MRT_Updater<MRT_PaginationState>) => {
    setPagination((prevState) => {
      if (typeof updater === 'function') {
        const newState = updater(prevState);
        if (newState.pageSize !== prevState.pageSize) {
          localStorage.setItem('tablePageSize', newState.pageSize.toString());
        }
        return newState;
      }
      return updater;
    });
  }, []);

  return (
    <MaterialReactTable
      columns={columns || []}
      data={data || []} // data is undefined on first render
      enableStickyHeader={rest.enableStickyHeader ?? true}
      enableTableFooter
      enableDensityToggle={false}
      enableColumnFilterModes
      enableColumnPinning
      enableMultiSort
      enableFullScreenToggle={false}
      muiTableContainerProps={{
        sx: {
          borderRadius: '4px',
          border: '2px solid #efefef',
          maxHeight: fullHeightTable ? 'auto' : 'calc(100vh - 320px) !important',
        },
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          border: 'none',
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          '& .MuiToolbar-gutters': {
            boxShadow: 'none',
          },
          '& .MuiTablePagination-root': {
            display: fullHeightTable ? 'none' : 'flex',
          },
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          fontWeight: 600,
          fontSize: '15px',
          height: '40px',
        },
      }}
      muiTopToolbarProps={{
        sx: {
          '& .MuiBox-root': {
            display: 'flex',
            alignItems: 'center',
            padding: '0px',
            margin: '5px 0px',
            '& > .MuiIconButton-root': {
              borderRadius: '4px',
              marginLeft: '2px',
              border: '2px solid #efefef',
            },
          },
        },
      }}
      muiToolbarAlertBannerProps={
        state?.isError
          ? {
              color: 'error',
              children: 'Network Error. Could not fetch data.',
            }
          : undefined
      }
      renderTopToolbarCustomActions={() => Actions}
      renderBottomToolbarCustomActions={() => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          {isDianomi && (
            <Box
              sx={{
                opacity: 0,
                '&:hover': {
                  opacity: 1,
                },
                padding: '10px',
                height: '100%',
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={fullHeightTable}
                    onChange={handleFullHeightToggle}
                    color="primary"
                  />
                }
                label="Full table view"
              />
            </Box>
          )}
        </Box>
      )}
      // tableInstanceRef={tableRef}
      onPaginationChange={onPaginationChange}
      renderEmptyRowsFallback={({ table }) => {
        if (data.length === 0 && table.getState().showAlertBanner) {
          return (
            <Box sx={{ p: 2, textAlign: 'center' }}>
              There has been an issue. Please reload and try again
            </Box>
          );
        }

        if (data.length === 0) {
          return <Box sx={{ p: 2, textAlign: 'center' }}>No data available</Box>;
        }
        if (table.getFilteredRowModel().rows.length === 0) {
          return <Box sx={{ p: 2, textAlign: 'center' }}>No data matches current filters.</Box>;
        }
        return <Box sx={{ p: 2, textAlign: 'center' }}>No data available</Box>;
      }}
      defaultColumn={{
        Cell: ({ renderedCellValue, column, row }) => {
          let columnId = column.id;
          if (columnId.toLowerCase().includes('custom')) {
            [columnId] = columnId.split('.');
          }

          return (
            <TableCell
              value={row.original[columnId] ?? renderedCellValue}
              column={{
                header: column.columnDef.header,
                accessor: column.columnDef.accessorKey as string,
              }}
              row={row.original}
              currency={currency ?? ''}
              formatNumbers={formatNumbers}
            />
          );
        },
      }}
      state={{
        density: 'compact' as const,
        pagination,
        ...state,
        isLoading: state?.isLoading || false,
        showAlertBanner: state?.isError || false,
        showProgressBars: state?.isFetching || false,
      }}
      initialState={{
        sorting: data[0] && 'date' in data[0] ? [{ id: 'date', desc: true }] : [],
        ...initialState,
      }}
      {...rest}
    />
  );
}
