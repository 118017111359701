/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps } from '@mui/material';
import React from 'react';
import InfoTooltip from '~/components/Commons/Form/InfoTooltip';

type InputWrapperProps = {
  children: React.ReactNode;
  wrapperProps?: BoxProps & { 'data-testid'?: string };
  tooltip?: React.ReactNode | string | undefined;
  isValid: boolean;
  isInvalid?: boolean;
  warning?: boolean;
  fullWidth?: boolean;
};

function InputWrapper({
  children,
  wrapperProps,
  tooltip = undefined,
  isValid,
  fullWidth = false,
  isInvalid = false,
  warning,
}: InputWrapperProps) {
  const { sx: wrapperSx, ...restWrapperProps } = wrapperProps || {};
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        width: '100%',
        ...wrapperSx,
      }}
      {...restWrapperProps}
    >
      <Box
        sx={{
          minWidth: fullWidth ? '100%' : '80%',
          marginRight: '10px',
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {children}
      </Box>
      {tooltip && (
        <InfoTooltip isValid={isValid} iconTestId="tooltip" forceZIndex={3000}>
          {tooltip}
        </InfoTooltip>
      )}
    </Box>
  );
}

export default InputWrapper;
