import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import useNotifications from '~/context/Notifications';
import { useClientSessionQuery } from '~/hooks/queries/useSessionPlQuery';
import { customFetch } from '~/utils/customFetch';

// Define response schema for type safety
const responseSchema = z.discriminatedUnion('success', [
  z.object({ success: z.literal(1) }),
  z.object({
    success: z.literal(0),
    error: z.string().optional(),
  }),
]);

type ResponseSchema = z.infer<typeof responseSchema>;

export type AudiencePreferenceMutationProps = {
  data: {
    entityId: number;
    entityType: 'cohort' | 'audience';
    action: 'add' | 'remove';
  };
};

export function useAudiencePreferenceMutation() {
  const queryClient = useQueryClient();
  const { data: sessionData, isLoading: isSessionLoading } = useClientSessionQuery();
  const { setTimedNotification } = useNotifications();

  return useMutation({
    mutationKey: ['useAudiencePreferenceMutation'],
    mutationFn: async ({ data }: AudiencePreferenceMutationProps) => {
      if (!isSessionLoading && !sessionData?.client.id) {
        throw new Error('No session data found');
      }

      const response = await customFetch<ResponseSchema>(
        `/authenticated/client/${sessionData?.id}/audiences/set-preference`,
        {
          method: 'POST',
          body: JSON.stringify(data),
          useNodeEndpoint: true,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.success === 0) {
        throw new Error('Failed to update audience preferences');
      }

      return response.data;
    },
    onError: (error: Error) => {
      setTimedNotification(
        'danger',
        `Error updating audience preferences: ${
          error.message || 'Please try again. If the issue persists contact support'
        }`,
        5000,
      );
    },
    onSuccess: () => {
      setTimedNotification('success', 'Audience preferences updated successfully', 5000);
      queryClient.invalidateQueries({
        queryKey: ['audiencePreferencesQuery'],
      });
    },
  });
}
