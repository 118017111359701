import React, { useState } from 'react';

// Core components
import Tooltip from '@mui/material/Tooltip';

// Material icons
import InfoIcon from '@mui/icons-material/Info';

import { ClickAwayListener } from '@mui/material';

type InfoTooltipProps = {
  children: React.ReactNode;
  isValid?: boolean;
  forceZIndex?: number;
  iconTestId?: string;
};

function InfoTooltip({
  children = null,
  isValid = undefined,
  forceZIndex = undefined,
  iconTestId = '',
}: InfoTooltipProps) {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        title={children}
        arrow
        open={open}
        slotProps={{
          tooltip: {
            sx: (theme) => ({
              backgroundColor: theme.palette?.info.main,
              padding: 1.5,
              '& .MuiTooltip-arrow': {
                color: theme.palette?.info.main,
              },
              fontSize: '0.85em',
            }),
          },
          ...(forceZIndex != null && {
            popper: {
              sx: {
                zIndex: forceZIndex,
              },
            },
          }),
        }}
      >
        <InfoIcon
          onClick={() => setOpen(!open)}
          color="info" // Tooltip message does not change based on status, so keep it the same
          fontSize="small"
          data-testid={iconTestId}
          aria-invalid={isValid}
        />
      </Tooltip>
    </ClickAwayListener>
  );
}

export default InfoTooltip;
