/* eslint-disable react/jsx-props-no-spreading */
import { ArrowDropDown } from '@mui/icons-material';
import { Box, FormHelperText, SxProps } from '@mui/material';
import { BoxProps } from '@mui/system';
import React, { useState } from 'react';

interface BaseTextFieldLookalikeProps {
  label: string;
  children: React.ReactNode;
  isValid?: boolean;
  error?: boolean;
  warning?: boolean;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  select?: boolean;
  childrenContainerSx?: SxProps;
}

type TextFieldLookalikeProps = BaseTextFieldLookalikeProps & {
  inputRef?: React.Ref<HTMLDivElement>;
  onClick?: undefined;
  id?: string;
};

interface ButtonTextFieldLookalikeProps extends BaseTextFieldLookalikeProps {
  onClick?: () => void;
  inputRef?: React.Ref<HTMLButtonElement>;
  id: string;
}

const fieldsetStyles = {
  position: 'relative' as const,
  border: '1px solid rgba(0, 0, 0, 0.23)',
  borderRadius: '4px',
  padding: '7px 12px 6px 12px',
  margin: 0,
  minHeight: '40px',
  '&[data-valid="true"]': {
    borderColor: 'success.main',
    borderWidth: '2px',
    padding: '6px 11px 5px 11px',
  },
  '&[data-warning="true"]': {
    borderColor: 'warning.light',
    borderWidth: '2px',
    padding: '6px 11px 5px 11px',
  },
  '&[data-error="true"]': {
    borderColor: 'error.main',
    borderWidth: '2px',
    padding: '6px 11px 5px 11px',
  },
  '&[data-disabled="true"]': {
    borderColor: 'rgba(0, 0, 0, 0.23)',
  },
  '&:hover': {
    borderColor: 'primary.main',
  },
  '&:focus-within': {
    borderColor: 'primary.main',
    borderWidth: '2px',
    padding: '6px 11px 5px 11px',
  },
  '&:focus-visible': {
    borderColor: 'primary.main',
    borderWidth: '2px',
    padding: '6px 11px 5px 11px',
  },
};

interface CustomFieldSetProps extends BoxProps {
  disabled?: boolean;
  isValid?: boolean;
  error?: boolean;
  warning?: boolean;
}

const CustomFieldSet = (props: CustomFieldSetProps) => {
  const { sx, disabled, isValid, error, warning, ...rest } = props;
  return (
    <Box
      component="fieldset"
      {...rest}
      data-disabled={disabled}
      data-valid={isValid}
      data-error={error}
      data-warning={warning}
      sx={{ ...fieldsetStyles, ...sx }}
    />
  );
};

const labelStyles = {
  position: 'absolute' as const,
  left: '12px',
  color: 'rgba(0, 0, 0, 0.6)',
  backgroundColor: '#fff',
  padding: '0 4px',
  transition: 'all 0.2s ease',
  transform: 'translate(0, 0) scale(1)',
  transformOrigin: 'left top',
  pointerEvents: 'none' as const,
  fontSize: '1rem',
  '&[data-disabled="true"]': {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  '&[data-isShrunk="true"]': {
    transform: 'translate(0, -18px) scale(0.75)',
  },
};

function TextFieldLookalike({
  children,
  label,
  isValid = false,
  error = false,
  errorMessage = '',
  warning = false,
  required = false,
  disabled = false,
  select = false,
  onClick,
  inputRef,
  childrenContainerSx,
  id,
}: ButtonTextFieldLookalikeProps | TextFieldLookalikeProps) {
  const [isFocused, setIsFocused] = useState(false);

  const hasContent = React.Children.toArray(children).filter(Boolean).length > 0;
  const isShrunk = isFocused || hasContent;

  const handleFocus = () => {
    if (!disabled) {
      setIsFocused(true);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const content = (
    <>
      <Box
        component="label"
        data-disabled={disabled}
        data-isShrunk={isShrunk}
        sx={labelStyles}
        htmlFor={id}
      >{`${label}${required ? ' *' : ''}`}</Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          transition: 'top 0.2s ease',
          fontSize: '1rem',
          minHeight: '1rem',
          ...childrenContainerSx,
        }}
      >
        {children || <>&nbsp;</>}
        {select && (
          <ArrowDropDown
            data-disabled={disabled}
            sx={{
              position: 'absolute',
              right: '-6px',
              color: 'grey.700',
              '&[data-disabled="true"]': {
                color: 'rgba(0, 0, 0, 0.26)',
              },
            }}
          />
        )}
      </Box>
    </>
  );

  return (
    <div style={{ position: 'relative', width: '100%', margin: '8px 0 0 0' }}>
      {onClick ? (
        <Box
          component="button"
          type="button"
          onClick={(e) => {
            handleFocus();
            onClick();
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={disabled}
          name={id}
          id={id}
          ref={inputRef}
          sx={{
            width: '100%',
            padding: 0,
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            textAlign: 'left',
            display: 'block',
            outline: 'none',
            backgroundColor: 'secondary.main',
            '&:disabled': {
              cursor: 'default',
            },
            '&:focus-within fieldset': {
              borderColor: 'primary.main',
              borderWidth: '2px',
              margin: '-2px',
            },
          }}
        >
          <CustomFieldSet disabled={disabled} isValid={isValid} error={error} warning={warning}>
            {content}
          </CustomFieldSet>
        </Box>
      ) : (
        <Box
          onFocus={handleFocus}
          onBlur={handleBlur}
          tabIndex={disabled ? -1 : 0}
          ref={inputRef}
          id={id}
          sx={{
            outline: 'none',
            backgroundColor: 'secondary.main',
            '&:focus-within fieldset': {
              borderColor: 'primary.main',
              borderWidth: '2px',
            },
          }}
        >
          <CustomFieldSet disabled={disabled} isValid={isValid} error={error} warning={warning}>
            {content}
          </CustomFieldSet>
        </Box>
      )}
      <FormHelperText
        sx={(theme) => ({
          ...(warning && errorMessage && theme.defaultStyles.warningContainer),
          ...(error && errorMessage && theme.defaultStyles.errorContainer),
        })}
      >
        {errorMessage}
      </FormHelperText>
    </div>
  );
}

export default TextFieldLookalike;
