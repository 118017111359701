/* eslint-disable react/jsx-props-no-spreading */
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { grey } from '@mui/material/colors';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

const styles = {
  formControl: {
    height: '30px',
    '& span': {
      color: grey[700],
      fontSize: '12px',
    },
  },
  greenCheckbox: {
    color: '#ccc',
    '&.Mui-checked': {
      color: (theme: Theme) => theme.palette?.success.light,
    },
    '&.Mui-disabled': {
      color: (theme: Theme) => theme.palette?.grey[400],
    },
  },
};

type Props = {
  label: string;
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start';
  onChange: (event: React.SyntheticEvent<Element, Event>, checked: boolean) => void;
  checked: boolean;
  name?: string;
  disabled?: boolean;
  sx?: SxProps;
};

function StyledCheckbox({
  label,
  labelPlacement = 'end',
  onChange,
  checked,
  name = undefined,
  disabled = false,
  sx = {},
}: Props) {
  return (
    <div>
      <FormControlLabel
        sx={[styles.formControl, sx].flat()}
        value={name}
        onChange={onChange}
        checked={checked}
        control={<Checkbox sx={styles.greenCheckbox} color="default" />}
        label={label}
        labelPlacement={labelPlacement}
        disabled={disabled}
      />
    </div>
  );
}

export default StyledCheckbox;
