import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { customFetch } from '~/utils/customFetch';
import { ErrorObjectString, nonNullable } from '~/utils/typescriptHelpers';
import { RawGeoData } from '../useGeoDataQuery';

type ResponseData = RawGeoData | ErrorObjectString;

async function getGeoData(queryIds: string[]) {
  const requestOptions = {
    headers: {
      'content-header': 'application/json',
    },
    params: { queryIds: queryIds.join(',') },
    useNodeEndpoint: true,
  };

  const { data: geoData } = await customFetch.get<ResponseData>(
    `/authenticated/regions/by-id`,
    requestOptions,
  );

  if ('error' in geoData) {
    throw new Error(geoData.error);
  }

  const processedData = geoData.map((geo) => {
    return {
      ...geo,
      parents: [geo.countryId, geo.stateId].filter(nonNullable),
      isSearch: false,
    };
  });

  return processedData;
}

export type GeoTargetingResponseData = Awaited<ReturnType<typeof getGeoData>>;

function useGeoByIdQuery<TData = GeoTargetingResponseData>(
  queryIds: string[],
  select?: (data: GeoTargetingResponseData) => TData,
) {
  return useQuery({
    queryKey: ['geoById', queryIds],
    queryFn: async () => {
      return getGeoData(queryIds);
    },
    select,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!queryIds && queryIds.length > 0,
    placeholderData: keepPreviousData,
    staleTime: Infinity,
  });
}

export default useGeoByIdQuery;
