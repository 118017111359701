/* eslint-disable react/jsx-props-no-spreading */
import {
  BlockOutlined,
  CheckCircleOutlineOutlined,
  CloseOutlined,
  Error,
} from '@mui/icons-material';
import BlockIcon from '@mui/icons-material/Block';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Grow,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Skeleton,
  SxProps,
  Theme,
  Tooltip,
  TooltipProps,
  useTheme,
} from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import { Virtualizer } from '@tanstack/react-virtual';
import * as React from 'react';
import { z } from 'zod';
import LoadingProgress from '~/components/Commons/LoadingProgress';
import InputWrapper from '~/components/Form/InputWrapper/InputWrapper';
import TextField from '~/components/Form/TextField/TextField';
import { usePostalCodeMutation } from '~/hooks/mutations/usePostalCodeMutation';
import { GeoData, RawGeoData } from '~/hooks/queries/useGeoDataQuery';
import useGeoByIdQuery from '../../../../hooks/queries/useGeoByIdQuery';
import { useScrollbarWidth } from '../../../../hooks/useScrollbarWidth';
import { GeoTargetingData } from './geo-targeting';

export const toolbarButtonStyles = {
  fontSize: '12px',
  padding: '5px 8px',
};

interface WarningTooltipProps extends TooltipProps {
  title: string;
}

export const WarningTooltip = ({ children, title, ...props }: WarningTooltipProps) => {
  return (
    <Tooltip
      arrow
      slotProps={{
        tooltip: {
          sx: (theme) => ({
            backgroundColor: theme.palette?.warning.main,
            '& .MuiTooltip-arrow': {
              color: theme.palette?.warning.main,
            },
          }),
        },
        popper: {
          style: {
            zIndex: 3000,
          },
        },
      }}
      title={
        <Box display="flex" alignItems="center" gap={1}>
          <Error color="secondary" />
          {title}
        </Box>
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};

const nameFormatting = (geo: RawGeoData[number], isSearch: boolean) => {
  if (geo.type === 'DMA' && !isSearch) {
    const slashIndex = geo.name.indexOf('/');

    return geo.name
      .substring(slashIndex + 1)
      .replace(/\s*\(.*?\)\s*/g, '')
      .trim();
  }
  const displayName = isSearch ? geo.extendedName : geo.name;
  // strips out where geo type is enclosed by curved brackets in the name
  const removeTypeFromName = displayName.replace(/\s*\(.*?\)\s*/g, '').trim();
  return removeTypeFromName;
};

export const processGeo = (data: RawGeoData, isSearch: boolean, parents?: number[]) =>
  data.map((geo) => {
    return {
      ...geo,
      name: nameFormatting(geo, isSearch),
      extendedName: isSearch ? nameFormatting(geo, isSearch) : geo.extendedName,
      parents: parents ?? [],
      isSearch,
    };
  });

// validate targeting is in format {include_region_ids: {id: number | string, name: string}[], exclude_region_ids: {id: number | string, name: string}[]}

const targetingSchema = z.object({
  include: z.array(z.object({ id: z.number(), label: z.string() })),
  exclude: z.array(z.object({ id: z.number(), label: z.string() })),
});

export const DisplayIncludeExclude = ({
  targeting,
  showIncludeBehavior = 'always',
}: {
  targeting: z.infer<typeof targetingSchema>;
  /**
   * when 'always', the include list will be shown even if it's empty
   *
   * when 'only-not-empty', the include list will only be shown if it's not empty
   * @default 'always'
   */
  showIncludeBehavior?: 'always' | 'only-not-empty';
}) => {
  if (
    targeting.exclude.length === 0 &&
    targeting.include &&
    targeting.include.length === 1 &&
    targeting.include.filter((item) => Number(item.id) === 722).length === 1
  ) {
    return <>{targeting.include[0].label}</>;
  }

  const shouldShowInclude = targeting.include.length > 0 || showIncludeBehavior === 'always';
  if (shouldShowInclude) {
    return (
      <DualTypeList
        items={{
          include: targeting.include.map((item) => item.label),
          exclude: targeting.exclude.map((item) => item.label),
        }}
      />
    );
  }
  return <SingleTypeList items={targeting.exclude.map((item) => item.label)} />;
};

const getColor = (variant: 'success' | 'error' | 'primary') => {
  switch (variant) {
    case 'success':
      return green;
    case 'error':
      return red;
    case 'primary':
    default:
      return grey;
  }
};

type TargetingVariant = 'success' | 'error' | 'primary';

export const TitleLabel = ({
  children,
  variant = 'success',
  sx = {},
}: {
  children: React.ReactNode;
  variant?: TargetingVariant;
  sx?: SxProps;
}) => {
  const theme = useTheme();
  const color = getColor(variant);
  return (
    <Box
      sx={{
        ...theme.defaultStyles.warningContainer,
        backgroundColor: variant === 'primary' ? color[300] : color[100],
        display: 'inline-block',
        color: color[900],
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

// While this might look similar to SingleTypeList, the styles are quite different so this is easier to manage
export function DualTypeList({ items }: { items?: { include?: string[]; exclude?: string[] } }) {
  const includedULRef = React.useRef<HTMLUListElement | null>(null);
  const scrollbarWidth = useScrollbarWidth();

  const includedRefIsOverflowing = (includedULRef.current?.scrollHeight ?? 0) > 150;
  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: includedRefIsOverflowing ? 2 : `${scrollbarWidth * 2}px`,
          width: '100%',

          '& ul': {
            margin: 0,
            maxHeight: '150px',
            whiteSpace: 'normal',
            '& li': {
              listStyle: 'initial',
              fontSize: '0.9rem',
            },
          },
          [theme.breakpoints.between('xs', 'md')]: {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <Box sx={{ overflow: 'auto', wordWrap: 'break-word', textAlign: 'center' }}>
          <TitleLabel variant="success">Included</TitleLabel>
          <ul ref={includedULRef}>
            {items?.include?.map((item) => (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                key={item}
              >
                <CheckCircleOutlineOutlined fontSize="inherit" color="success" />
                <li style={{ listStyle: 'none', marginLeft: '5px', textAlign: 'start' }}>{item}</li>
              </Box>
            ))}
          </ul>
        </Box>

        <Box sx={{ overflow: 'auto', wordWrap: 'break-word', textAlign: 'center' }}>
          <Box
            sx={(theme) => ({
              ...theme.defaultStyles.warningContainer,
              backgroundColor: red[100],
              display: 'inline-block',
              color: red[900],
            })}
          >
            Excluded
          </Box>
          <ul>
            {items?.exclude?.map((item) => (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="flex-start"
                key={item}
              >
                <BlockOutlined fontSize="inherit" color="error" />
                <li style={{ listStyle: 'none', marginLeft: '5px', textAlign: 'start' }}>{item}</li>
              </Box>
            ))}
          </ul>
        </Box>
      </Box>
    </>
  );
}

// while this might look similar to DualTypeList, the styles are quite different so this is easier to manage
export function SingleTypeList({
  items = [],
  variant = 'error',
  label = 'Excluded',
  columnCount = 2,
}: {
  items?: string[];
  variant?: TargetingVariant;
  label?: string;
  columnCount?: number;
}) {
  const scrollbarWidth = useScrollbarWidth();

  const columns = React.useMemo(() => {
    const arrangedItems: string[][] = Array.from({ length: columnCount }, () => []);
    items.forEach((item, index) => {
      arrangedItems[index % columnCount].push(item);
    });
    return arrangedItems;
  }, [columnCount, items]);

  return (
    <>
      <Box
        sx={(theme) => ({
          textAlign: 'center',
          maxHeight: '150px',
          overflow: 'auto',
          width: '100%',
          display: 'grid',
          columnGap: `${scrollbarWidth * 2}px`,
          gridTemplateColumns: `repeat(${columnCount}, 1fr)`,
          '& ul': {
            wordWrap: 'break-word',
            margin: 0,
            whiteSpace: 'normal',
            overflowWrap: 'anywhere',
            '& li': {
              listStyle: 'initial',
              fontSize: '0.9rem',
            },
          },
          [theme.breakpoints.between('xs', 'md')]: {
            gridTemplateColumns: '1fr',
          },
        })}
      >
        <TitleLabel
          variant={variant}
          sx={{ display: 'inline-block', justifySelf: 'center', gridColumn: '1 / -1' }}
        >
          {label}
        </TitleLabel>

        {columns.map((col, colIndex) => {
          const colKey = `list-column-${colIndex}`;
          return (
            <ul key={colKey}>
              {col.map((item, itemIndex) => {
                const itemKey = `list-item-${itemIndex}`;
                return (
                  <Box
                    key={itemKey}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    {variant === 'error' ? (
                      <BlockOutlined fontSize="inherit" color="error" />
                    ) : (
                      <CheckCircleOutlineOutlined fontSize="inherit" color="success" />
                    )}

                    <li style={{ listStyle: 'none', marginLeft: '5px', textAlign: 'start' }}>
                      {item}
                    </li>
                  </Box>
                );
              })}
            </ul>
          );
        })}
      </Box>
    </>
  );
}

export const isChildOf = (parentId: number, childId: number, allData: GeoData = []): boolean => {
  const currentChild = allData.find((data) => data.id === childId);
  if (!childId) {
    return false;
  }
  // If child of country group, ensure 'all countries' country group is in parents
  if (currentChild?.type === 'Country' && currentChild?.parents.length > 0) {
    const newChild = { ...currentChild, parents: [...currentChild.parents, 722] };
    return newChild?.parents.includes(parentId) || false;
  }

  return currentChild?.parents.includes(parentId) || false;
};

export type GeoTargetingHeaderProps = {
  children: React.ReactNode;
};

const GeoTargetingHeader: React.FC<GeoTargetingHeaderProps> = ({ children }) => {
  const labelStyle = {
    fontWeight: '600',
    width: '100%',
    backgroundColor: grey[900],
    textAlign: 'center',
    color: grey[100],
  };

  return (
    <Box
      sx={(theme) => ({
        ...theme.defaultStyles.errorContainer,
        ...labelStyle,
        fontSize: '12px',
      })}
    >
      {children}
    </Box>
  );
};

type GeoTargetingAction = 'include' | 'exclude';

type RegionEntityListProps = {
  entities: GeoData;
  targetingData: GeoTargetingData;
  onInputChange: (data: GeoTargetingData) => void;
  setTargeting: React.Dispatch<React.SetStateAction<GeoTargetingData>>;
  selectedGeos: number[];
  setSelectedGeos: (val: number[]) => void;
  isFetching: boolean;
  virtualiser: Virtualizer<HTMLDivElement, Element>;
  isSearchData: boolean;
  onlyExclude?: boolean;
};

export const RegionEntityList = ({
  entities,
  onInputChange,
  targetingData,
  setTargeting,
  selectedGeos,
  setSelectedGeos,
  isFetching,
  virtualiser,
  isSearchData,
  onlyExclude = false,
}: RegionEntityListProps) => {
  const { include_region_ids: includedIds, exclude_region_ids: excludedIds } = targetingData;
  const { data: targetedGeos } = useGeoByIdQuery([...excludedIds, ...includedIds].map(String));

  const getAllCurrentGeoData = () => {
    if (targetedGeos) {
      return [...entities, ...targetedGeos];
    }

    return entities;
  };

  const allCurrentGeos = getAllCurrentGeoData();

  const handleGeoItemClick = (geo: GeoData[number], index: number) => {
    if (geo.hasChildren) {
      // append geo.id if is child of selectedGeos.at(-1)
      // remove from selectedGeos (and all geos to the right of it) if geo.id is in selectedGeos
      const parent = selectedGeos.at(-1);
      if (!parent) {
        // empty selectedGeos, start filling it
        setSelectedGeos([geo.id]);
      } else if (isChildOf(parent, geo.id, allCurrentGeos)) {
        // append geo.id if is child of selectedGeos.at(-1)
        setSelectedGeos([...selectedGeos, geo.id]);
      } else if (selectedGeos.includes(geo.id)) {
        const indexOfGeo = selectedGeos.indexOf(geo.id);
        // remove from selectedGeos (and all geos to the right of it) if geo.id is in selectedGeos
        setSelectedGeos(selectedGeos.filter((id, i) => i < indexOfGeo));
      } else if (selectedGeos.at(-2) && selectedGeos.at(-2) === geo.parents.at(-1)) {
        selectedGeos.pop();
        // should be a sibling so swap the last parent with the new geo
        setSelectedGeos([...selectedGeos, geo.id]);
      } else if (selectedGeos.length > 0 && selectedGeos.length !== geo.parents.length) {
        const highestParentGeo = selectedGeos.shift() ?? 0; // checking length above so should be safe
        // remove all geos to the right of the highest parent geo (for moving from deeper to higher level)
        setSelectedGeos([highestParentGeo, geo.id]);
      } else {
        // if we're at the top level, just select the geo
        setSelectedGeos([geo.id]);
      }
    }
  };

  const handleActionToggle = (id: number, actionType: GeoTargetingAction) => {
    const newTargetingData: GeoTargetingData = {
      include_region_ids: [...targetingData.include_region_ids],
      exclude_region_ids: [...targetingData.exclude_region_ids],
    };

    const { include_region_ids: newIncluded, exclude_region_ids: newExcluded } = newTargetingData;
    if (actionType === 'include') {
      if (!newIncluded.includes(id)) {
        const removeIncludedChildGeos = newIncluded.filter(
          (regionId) => !isChildOf(id, Number(regionId), allCurrentGeos),
        );
        newTargetingData.include_region_ids = [...removeIncludedChildGeos, id];
        // Remove all included geos if 'all countries' country group if id is 722
        if (id === 722) {
          newTargetingData.include_region_ids = [id];
        }
      } else {
        newTargetingData.include_region_ids = newIncluded.filter((regionId) => id !== regionId);
        newTargetingData.exclude_region_ids = newExcluded.filter(
          (regionId) => !isChildOf(id, Number(regionId), allCurrentGeos),
        );
      }
    }

    if (actionType === 'exclude') {
      if (!onlyExclude && targetingData.include_region_ids.length === 0) {
        return newTargetingData;
      }

      if (!newExcluded.includes(id)) {
        const removeChildOfSelectedParent = newExcluded.filter(
          (regionId) => !isChildOf(id, Number(regionId), allCurrentGeos),
        );
        newTargetingData.exclude_region_ids = [...removeChildOfSelectedParent, id];
      } else {
        newTargetingData.exclude_region_ids = newExcluded.filter((regionId) => id !== regionId);
      }
    }

    // Check if both include and exclude arrays are empty, filter excludedIds
    if (
      newTargetingData.exclude_region_ids.length === 0 &&
      newTargetingData.include_region_ids.length === 0
    ) {
      if (actionType === 'include') {
        newExcluded.filter((regionId) => isChildOf(id, Number(regionId), allCurrentGeos));
      }
    }

    // Update state with new targeting data and invoke onInputChange callback
    setTargeting(newTargetingData);
    onInputChange(newTargetingData);

    return newTargetingData;
  };

  const inclusionRules = (geoItem: GeoData[number]) => {
    // If onlyExclude is true, no items can be included
    if (onlyExclude) {
      return false;
    }

    // If "All Countries" (id: 722) is included, no other items can be included
    if (geoItem.id !== 722 && includedIds.length > 0 && includedIds.every((id) => id === 722)) {
      return false;
    }

    // Next two rules handle cases where included item displays in multiple country groups
    // e.g. Afghanistan displays in both Dianomi OFAC and Asia
    // If item is already included, it can be toggled
    if (includedIds.some((id) => id === geoItem.id)) {
      return true;
    }

    // If item is already excluded, it cannot be included
    if (excludedIds.some((id) => id === geoItem.id)) {
      return false;
    }

    // If item is a child of any included or excluded item, it cannot be included
    // (prevents nested inclusions/exclusions)
    if (
      includedIds.some((id) => isChildOf(Number(id), geoItem.id, allCurrentGeos)) ||
      excludedIds.some((id) => isChildOf(Number(id), geoItem.id, allCurrentGeos))
    ) {
      return false;
    }

    // If current item is a child of "All Countries" (id: 722) and any other country is already included,
    // allow including this item since it's not redundant with the parent
    if (isChildOf(722, geoItem.id, allCurrentGeos) && includedIds.some((id) => id !== 722)) {
      return true;
    }

    // By default, item can be included
    return true;
  };

  const exclusionRules = (geoItem: GeoData[number]) => {
    // If onlyExclude is true, all items can be excluded
    if (onlyExclude) {
      return true;
    }

    // Cannot exclude anything if nothing is included
    if (targetingData.include_region_ids.length === 0) {
      return false;
    }

    // Next two rules handle cases where included item displays in multiple country groups
    // If item is already excluded, it can be toggled
    if (excludedIds.some((id) => id === geoItem.id)) {
      return true;
    }

    // If item is already included, it cannot be excluded
    if (includedIds.some((id) => id === geoItem.id)) {
      return false;
    }

    // If item is a child of an excluded item, it cannot be excluded again
    // (prevents redundant exclusions)
    if (excludedIds.some((id) => isChildOf(Number(id), geoItem.id, allCurrentGeos))) {
      return false;
    }

    // If item is a child of an included item, it can be excluded
    if (includedIds.some((id) => isChildOf(Number(id), geoItem.id, allCurrentGeos))) {
      return true;
    }

    // If there are included items but current item isn't a child of any of them,
    // it cannot be excluded (can only exclude from what's included)
    if (
      includedIds.length > 0 &&
      includedIds.some((id) => !isChildOf(Number(id), geoItem.id, allCurrentGeos))
    ) {
      return false;
    }

    // By default, item can be excluded if it passes all above conditions
    return true;
  };

  return (
    <Box>
      <List
        sx={{
          padding: 0,
          margin: 0,
        }}
      >
        {entities.length === 0 && !isSearchData && (
          <Box
            sx={{ margin: '15px auto', textAlign: 'center', color: grey[500], maxWidth: '300px' }}
          >
            There has been an error loading data, please refresh the page and try again. If the
            issue persists contact my@dianomi.com
          </Box>
        )}

        {entities.length === 0 && isSearchData && (
          <Box sx={{ marginTop: '15px', textAlign: 'center', color: grey[500] }}>
            No locations were found
          </Box>
        )}

        {virtualiser.getVirtualItems().map((virtualItem) => {
          const geo = entities[virtualItem.index];
          if (!geo) {
            return null;
          }

          const activeItem = selectedGeos.includes(geo.id);
          const activeItemBGColor = activeItem ? grey[100] : 'white';

          const getBorderColor = (action: GeoTargetingAction, theme: Theme) => {
            const targeting = action === 'include' ? includedIds : excludedIds;
            const activeColor =
              action === 'include' ? theme.palette.success.main : theme.palette.error.main;
            return targeting.includes(geo.id) ? activeColor : theme.palette.primary.main;
          };
          const childItemIndent = geo.parents.length;
          const itemIndentSize = geo.isSearch ? 0 : childItemIndent * 20;

          return (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                transform: `translateY(${virtualItem.start}px)`,
                paddingLeft: `${itemIndentSize}px`,
              }}
              ref={virtualiser.measureElement}
              data-index={virtualItem.index}
              // Tanstack virtual updated with the update to Material React
              // Table and added BigInt to the key type, which our version of
              // react isn't compatible with, but works for our use case anyway
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              key={virtualItem.key as any}
            >
              <ListItem sx={{ width: '100%', padding: '0px', position: 'relative' }}>
                <ListItemButton
                  aria-label={`${geo.name} list item`}
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%',
                    fontSize: '13px',
                    paddingX: 0.5,
                    height: `${virtualItem.size}px`,
                    textAlign: 'center',
                    zIndex: '100',
                    borderBottom: `1px solid ${grey[300]}`,
                    backgroundColor: activeItemBGColor,
                    '&:hover': {
                      backgroundColor: activeItemBGColor,
                    },
                  }}
                  onClick={() => handleGeoItemClick(geo, virtualItem.index)}
                >
                  <ListItemButton
                    sx={{
                      width: '100%',
                      '&:hover': { backgroundColor: 'transparent' },
                      padding: 0,
                    }}
                    disableRipple
                  >
                    <ExpandMoreIcon
                      sx={{
                        transform: selectedGeos.includes(geo.id)
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                        transition: 'transform 0.3s',
                        color: geo.hasChildren && geo.type !== 'City' ? grey[800] : grey[300],
                      }}
                    />
                    <Box sx={{ textTransform: 'capitalize' }}>
                      {isSearchData && geo.isSearch ? geo.extendedName : geo.name}
                    </Box>
                    <Box sx={{ marginLeft: '5px', fontSize: '11px', color: grey[500] }}>
                      {geo.type === 'State' && geo.parents.at(-1) !== 184 ? 'Region' : geo.type}
                    </Box>
                  </ListItemButton>
                  <Box sx={{ display: 'inline-flex', zIndex: '1000' }}>
                    {inclusionRules(geo) && (
                      <IconButton
                        size="small"
                        aria-label="include"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleActionToggle(geo.id, 'include');
                        }}
                        sx={(theme) => ({
                          transition: theme.defaultStyles.transition,
                          border: theme.defaultStyles.border,
                          marginX: 0.5,
                          borderColor: getBorderColor('include', theme),
                        })}
                        color={includedIds.includes(geo.id) ? 'success' : 'default'}
                      >
                        <CheckIcon fontSize="inherit" />
                      </IconButton>
                    )}
                    {exclusionRules(geo) && (
                      <IconButton
                        size="small"
                        aria-label="exclude"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleActionToggle(geo.id, 'exclude');
                        }}
                        sx={(theme) => ({
                          transition: theme.defaultStyles.transition,
                          border: theme.defaultStyles.border,
                          marginX: 0.5,
                          borderColor: getBorderColor('exclude', theme),
                        })}
                        color={excludedIds.includes(geo.id) ? 'error' : 'default'}
                      >
                        <BlockIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </Box>
                  {isFetching && selectedGeos.at(-1) === geo.id && (
                    <LinearProgress
                      data-testid="loading-progress"
                      sx={{ width: '100%', position: 'absolute', bottom: '0', left: '0' }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </Box>
          );
        })}
      </List>
    </Box>
  );
};

type TargetingPreviewListProps = {
  targeting: GeoTargetingData;
  handleRemoveGeo: (action: 'include' | 'exclude' | 'postal', id: string) => void;
};

export const TargetingPreviewList = ({ targeting, handleRemoveGeo }: TargetingPreviewListProps) => {
  const {
    data: targetedGeos,
    isInitialLoading,
    isFetching,
  } = useGeoByIdQuery(
    [...targeting.exclude_region_ids, ...targeting.include_region_ids].map(String),
  );
  const labelStyle = {
    borderRadius: '7px',
    padding: '3px',
    fontWeight: '600',
    width: '100%',
    backgroundColor: grey[900],
    textAlign: 'center',
    color: grey[100],
  };

  const listItemStyle = {
    fontSize: '13px',
    color: grey[600],
    padding: '5px 0px',
    opacity: isFetching ? '0.5 !important' : 1,
  };

  const includedIds = targeting.include_region_ids;
  const isZipAndGeoTargeting =
    includedIds.length > 0 &&
    !targetedGeos?.every((geo) => geo.type === 'Postal Code') &&
    !targetedGeos?.every((geo) => geo.type !== 'Postal Code');

  return (
    <>
      {!targetedGeos && isInitialLoading ? (
        <LoadingProgress loading />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'relative',
          }}
          data-testid={isFetching ? 'loading-progress' : undefined}
        >
          {isZipAndGeoTargeting && (
            <Box sx={{ display: 'inline-flex', width: '100%', paddingBottom: '10px' }}>
              <Box
                sx={(theme) => ({
                  ...theme.defaultStyles.warningContainer,
                  display: 'inline-block',
                  margin: '0 auto',
                })}
              >
                <>
                  Warning: When applying postal code targets, we do not automatically account for
                  conflicting geographic targets like our standard geo targeting does. Please review
                  to ensure there are no overlaps.
                </>
              </Box>
            </Box>
          )}
          {isFetching && (
            <Skeleton
              variant="rounded"
              animation="wave"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 0,
                opacity: 0.75,
              }}
            />
          )}
          {includedIds.length > 0 && <GeoTargetingHeader>Included</GeoTargetingHeader>}

          <List sx={{ p: 0 }}>
            {includedIds.map((id) => {
              const entity = targetedGeos?.find((geo) => geo.id === Number(id));

              if (!entity) {
                return null;
              }
              return (
                <Grow in key={entity?.id}>
                  <ListItem key={entity?.id} sx={listItemStyle}>
                    <Box display="flex" alignItems="center">
                      <IconButton
                        size="small"
                        aria-label={`remove included ${entity.name}`}
                        onClick={() =>
                          handleRemoveGeo(
                            entity.type !== 'Postal Code' ? 'include' : 'postal',
                            entity.id.toString(),
                          )
                        }
                        sx={(theme) => ({
                          transition: theme.defaultStyles.transition,
                          border: theme.defaultStyles.border,
                          marginX: 0.5,
                          fontSize: '8px',
                          height: '100%',
                        })}
                      >
                        <CloseOutlined fontSize="inherit" />
                      </IconButton>
                      {entity.name}
                      <CheckIcon fontSize="inherit" sx={{ marginLeft: '5px' }} color="success" />
                    </Box>
                  </ListItem>
                </Grow>
              );
            })}
          </List>

          {targeting.exclude_region_ids.length > 0 && (
            <GeoTargetingHeader>Excluded</GeoTargetingHeader>
          )}

          <List sx={{ p: 0 }}>
            {targeting.exclude_region_ids.map((id) => {
              const entity = targetedGeos?.find((geo) => geo.id === Number(id));
              if (!entity) {
                return null;
              }
              return (
                <Grow in key={entity?.id}>
                  <ListItem key={entity?.id} sx={listItemStyle}>
                    <Box display="flex" alignItems="center">
                      <IconButton
                        size="small"
                        aria-label={`remove excluded ${entity.name}`}
                        onClick={() => handleRemoveGeo('exclude', entity.id.toString())}
                        sx={(theme) => ({
                          transition: theme.defaultStyles.transition,
                          border: theme.defaultStyles.border,
                          marginX: 0.5,
                          fontSize: '8px',
                          height: '100%',
                        })}
                      >
                        <CloseOutlined fontSize="inherit" />
                      </IconButton>
                      {entity?.extendedName}
                      <BlockIcon fontSize="inherit" sx={{ marginLeft: '5px' }} color="error" />
                    </Box>
                  </ListItem>
                </Grow>
              );
            })}
          </List>

          {targeting.exclude_region_ids.length === 0 && includedIds.length === 0 && (
            <>
              <Box
                sx={(theme) => ({
                  ...labelStyle,
                  ...theme.defaultStyles.errorContainer,
                  backgroundColor: grey[900],
                  color: grey[100],
                  fontSize: '12px',
                })}
              >
                No Geos Selected
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export const ZipCodeTextField = ({
  targeting,
  onConfirm,
}: {
  targeting: GeoTargetingData;
  onConfirm: (data: GeoTargetingData) => void;
}) => {
  // const [countryField, setCountryField] = React.useState<string>('');
  const [hasInvalidCodes, setInvalidCodes] = React.useState<boolean>(false);
  const [inputValue, setInputValue] = React.useState('');
  const mutation = usePostalCodeMutation();

  const isInvalid = hasInvalidCodes && !mutation.isPending;

  const handleValidateCodes = (
    event: React.KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if ((event as React.KeyboardEvent<HTMLDivElement>).key === 'Enter' || event.type === 'click') {
      event.preventDefault();
      if (inputValue) {
        const processedValue = inputValue
          .toUpperCase()
          .split(/[\n,]+/)
          .map((val) => val.trim())
          .filter((val) => val !== '');

        if (processedValue) {
          mutation.mutateAsync({ data: processedValue }).then((res) => {
            if (res.data.success === 1) {
              const { body: postalCodeData } = res.data;

              const validCodes = postalCodeData.valid.filter(
                (code) => !targeting.include_region_ids.includes(Number(code)),
              );
              const newTargetingData = {
                ...targeting,
                include_region_ids: [...targeting.include_region_ids, ...validCodes.map(Number)],
              };
              onConfirm(newTargetingData);
              setInvalidCodes(postalCodeData.invalid.length > 0);
              setInputValue(postalCodeData.invalid.toString());
            }
          });
        }
      }
    }
  };

  return (
    <>
      <InputWrapper
        isValid
        tooltip="Select the country of the postal codes you would like to use"
        wrapperProps={{ sx: { justifyContent: 'flex-start', '> :first-child': { width: '100%' } } }}
      >
        <TextField
          name="postal-code-country-field"
          label="Select Country Postal Code"
          id="zip-code-field"
          value="United States"
          size="small"
          // onChange={(e) => setCountryField(e.target.value)}
          error={!targeting}
          errorText={!targeting ? 'Select the country you would like to target' : ''}
          select
          disabled
        >
          <MenuItem value="United States">United States</MenuItem>
          <MenuItem value="United Kingdom">United Kingdom</MenuItem>
          <MenuItem value="United Canada">Canada</MenuItem>
        </TextField>
      </InputWrapper>

      <InputWrapper
        isValid
        tooltip="Please enter zip codes for validation here. Note that zip codes need to either be comma seperated or each zip code entry must be on a new line. A combination of both is accepted. Any other format will not be validated"
        wrapperProps={{ sx: { justifyContent: 'flex-start', '> :first-child': { width: '100%' } } }}
      >
        <>
          <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
            <LoadingProgress loading={mutation.isPending} />
          </Box>

          <TextField
            multiline
            minRows={4}
            maxRows={10}
            name="zip-code-field"
            label="Enter Zip/Postal Code"
            id="zip-code-field"
            value={inputValue}
            onChange={(e) =>
              setInputValue(() => {
                if (hasInvalidCodes) {
                  setInvalidCodes(false);
                }
                return e.target.value;
              })
            }
            size="small"
            error={isInvalid}
            errorText={
              isInvalid
                ? 'The above postal/zip codes appear to be invalid. Please review and try again.'
                : 'Each postal code must be comma-separated or entered on a new line. A combination of both is allowed.'
            }
            onKeyDown={(e) => handleValidateCodes(e)}
            sx={{
              position: 'relative',
              width: '100%',
              '& .MuiAutocomplete-inputRoot': {
                justifyContent: 'flex-start !important',
                display: 'inline',
                width: '100%',
              },
              '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
                width: '100%',
              },
            }}
            disabled={mutation.isPending}
          />
        </>
      </InputWrapper>

      <Button
        onClick={(e) => handleValidateCodes(e)}
        type="submit"
        variant="contained"
        sx={{ margin: '5px 0px', display: 'flex', justifyContent: 'center' }}
        disabled={!inputValue}
      >
        Validate
      </Button>
    </>
  );
};
