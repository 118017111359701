import * as React from 'react';

/**
 *
 * Calculates the width of the browsers scrollbar, can vary a lot based on device/browser
 * @returns number
 */
export const useScrollbarWidth = () =>
  React.useMemo(() => {
    const scrollDiv = document.createElement('div');
    scrollDiv.style.position = 'absolute';
    scrollDiv.style.top = '-9999px';
    scrollDiv.style.overflow = 'scroll';
    document.body.appendChild(scrollDiv);
    const width = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    return width;
  }, []);
