import { InfoOutlined, OpenInNew, Paid, Star, StarBorder } from '@mui/icons-material';
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  LinearProgress,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
} from '@mui/material';
import { yellow } from '@mui/material/colors';
import { useVirtualizer } from '@tanstack/react-virtual';
import React from 'react';
import { FieldError, RefCallBack } from 'react-hook-form';
import { Link } from 'react-router-dom';
import InfoTooltip from '~/components/Commons/Form/InfoTooltip';
import Checkbox from '~/components/Form/Checkbox';
import InputWrapper from '~/components/Form/InputWrapper/InputWrapper';
import TextField from '~/components/Form/TextField/TextField';
import { useAudiencePreferenceMutation } from '~/hooks/mutations/useAudiencePreferenceMutation/useAudiencePreferenceMutation';
import { Audience, Audiences, Cohort } from '~/hooks/queries/useAdmanagerData/types';
import { useAudiencePreferenceQuery } from '~/hooks/queries/useAudiencePreferenceQuery/useAudiencePreferenceQuery';
import useCohortQuery, { CohortType } from '~/hooks/queries/useCohortQuery/useCohortQuery';
import useStorageState from '~/hooks/useStorageState';
import { nonNullable } from '~/utils/typescriptHelpers';
import TextFieldLookalike from '../../../../components/Form/TextFieldLookALike/TextFieldLookALike';
import { SingleTypeList } from '../geo-targeting/utils';

export const getUserSegmentsValue = (
  cohortTypes: Array<CohortType>,
  audiences: Audiences,
  cohorts: Array<Cohort>,
) => {
  return cohortTypes
    .map((cohortType) => {
      const selectedAudiences =
        cohortType.audiences.filter(
          (audience) => audiences.include_audience_ids?.includes(audience.id),
        ) ?? [];
      const selectedCohorts =
        cohorts?.filter((cohort) => cohortType.id === cohort.cohortTypeId) ?? [];

      if (selectedCohorts.length + selectedAudiences.length === 0) {
        return null;
      }

      return {
        ...cohortType,
        audiences: selectedAudiences,
        cohorts: selectedCohorts,
      };
    })
    .filter(nonNullable);
};

const userSegmentDialogStyles = {
  audienceContainer: {
    display: 'flex',
    gap: 4,
    width: '100%',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
};

export type PairedItemsReturnType = (Audience | Cohort)[];

const urlAudienceParamMap: Record<string, string> = {
  liveramp: 'Liveramp',
  neustar: 'Neustar',
  dianomi: 'Dianomi',
};

const getAudienceManagerPath = (
  type: 'cohort' | 'audience',
  audienceId: number,
  source: string | number,
) => {
  const searchParams = new URLSearchParams();

  // If cohort, we pass the cohort type id, if audience, we pass the source
  // Any cohort with a type id other than 1 is considered a first party audience

  if (type === 'cohort' && source === 1) {
    searchParams.set('tab', 'Dianomi');
  } else if (type === 'cohort' && source !== 1) {
    searchParams.set('tab', 'First Party');
  } else if (type === 'audience') {
    searchParams.set('tab', urlAudienceParamMap[source] ?? 'First Party');
  }

  return `../account/audience-manager?audienceId=${audienceId}&tab=${searchParams.get('tab')}`;
};

const USD = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const virtualListItemStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: '1px solid #E0E0E0',
  minHeight: '50px',
};

export const audiencePreferencesToggleStorageKey = 'audience-targeting-preferences-only';

export const useAudiencePreferencesToggle = () => {
  const [localStorePreferencesOnly, setLocalStorePreferencesOnly] = useStorageState(
    audiencePreferencesToggleStorageKey,
    false,
    'local',
  );

  return [localStorePreferencesOnly, setLocalStorePreferencesOnly] as const;
};

type VirtualListItemProps = {
  item: Audience | Cohort;
  isChecked: boolean;
  audienceManagerPath: string;
  audienceManagerEnabled: boolean;
  handleAudienceChange: (checked: boolean, newAudience: number) => void;
  handleCohortChange: (checked: boolean, newCohort: Cohort) => void;
};

const VirtualListItem = ({
  item,
  isChecked,
  audienceManagerPath,
  audienceManagerEnabled,
  handleAudienceChange,
  handleCohortChange,
}: VirtualListItemProps) => {
  const { data: preferences } = useAudiencePreferenceQuery();
  const {
    mutate,
    isPending,
    variables: mutationVars,
    error: mutationError,
  } = useAudiencePreferenceMutation();

  const entityType = 'source' in item ? 'audience' : 'cohort';
  const isPreference =
    mutationVars?.data && !mutationError
      ? mutationVars?.data.entityId === item.id && mutationVars.data.action === 'add'
      : preferences?.some(
          (preference) => preference.entityType === entityType && preference.entityId === item.id,
        );
  const isItemPending = mutationVars?.data.entityId === item.id && isPending;
  const action = isPreference ? 'remove' : 'add';

  return (
    <Box sx={virtualListItemStyle} data-testid={`audience-targeting-item-${item.id}`}>
      <Checkbox
        name={item.name}
        label={item.name}
        onChange={(e, checked) =>
          'source' in item
            ? handleAudienceChange(checked, item.id)
            : handleCohortChange(checked, item)
        }
        sx={{
          color: 'primary.main',
          height: 'unset',
          '& .MuiTypography-root': {
            overflowWrap: 'anywhere',
          },
        }}
        checked={isChecked}
      />
      <Box display="flex" alignItems="center" gap={1}>
        {'source' in item &&
          item.description && ( // Need both checks for type safety and ensuring description is not empty
            <InfoTooltip forceZIndex={3000}>{item.description}</InfoTooltip>
          )}
        {'explanation' in item && item.explanation && (
          <InfoTooltip forceZIndex={3000}>{item.explanation}</InfoTooltip>
        )}
        {audienceManagerEnabled && (
          <Chip
            icon={<Paid fontSize="small" sx={{ display: 'flex' }} />}
            label={`CPM Cost: ${USD.format('cpm' in item ? item.cpm ?? 0 : 0)}`}
            size="small"
            color="secondary"
          />
        )}
        {audienceManagerEnabled && audienceManagerPath ? (
          <Tooltip
            slotProps={{
              popper: {
                style: {
                  zIndex: 3000,
                },
              },
            }}
            title={`View stats for ${item.name} in the Audience Manager`}
          >
            <Link target="_blank" to={audienceManagerPath}>
              <Chip
                icon={<OpenInNew fontSize="small" sx={{ display: 'flex' }} />}
                label="View Stats"
                size="small"
                color="secondary"
                sx={{
                  border: '1px solid #E0E0E0',
                }}
              />
            </Link>
          </Tooltip>
        ) : null}
        <IconButton
          sx={{
            color: isPreference ? yellow[800] : 'primary',
          }}
          onClick={() => {
            mutate({
              data: {
                entityId: item.id,
                entityType,
                action,
              },
            });
          }}
        >
          {isItemPending ? (
            <CircularProgress size={20} data-testid="loading-progress" />
          ) : (
            <Tooltip
              slotProps={{
                popper: {
                  style: {
                    zIndex: 3000,
                  },
                },
              }}
              title={isPreference ? 'Remove from Favourites' : 'Add to Favourites'}
            >
              <>
                {audienceManagerEnabled ? (
                  <>
                    {isPreference ? (
                      <Star fontSize="small" data-testid="preference-checked-icon" />
                    ) : (
                      <StarBorder fontSize="small" data-testid="preference-unchecked-icon" />
                    )}
                  </>
                ) : null}
              </>
            </Tooltip>
          )}
        </IconButton>
      </Box>
    </Box>
  );
};

type VirtualisedAudienceListProps = {
  items: PairedItemsReturnType;

  cohortValue: Array<Cohort>;
  audienceValue: Array<number>;
  searchValue?: string;
  userSegmentsContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  preferencesOnly: boolean;
  itemTabs: string[];
  tab: string;
  setTab: (tab: string) => void;
  setPreferencesOnly: (starredOnly: boolean) => void;
  handleSearch: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleCohortChange: (checked: boolean, newCohort: Cohort) => void;
  handleAudienceChange: (checked: boolean, newAudience: number) => void;
  showAudienceManager: boolean;
};

export function VirtualisedAudienceList({
  items,
  cohortValue,
  audienceValue,
  searchValue,
  userSegmentsContainerRef,
  itemTabs,
  tab,
  handleSearch,
  handleCohortChange,
  handleAudienceChange,
  setTab,
  preferencesOnly,
  setPreferencesOnly,
  showAudienceManager,
}: VirtualisedAudienceListProps) {
  // disabling react-compiler memoization for this component
  // eslint-disable-next-line lines-around-directive, react-compiler/react-compiler
  'use no memo';
  const [, setLocalStorePreferencesOnly] = useAudiencePreferencesToggle();
  const { isFetching } = useCohortQuery({ hasPreferences: preferencesOnly });

  const audienceVirtualiser = useVirtualizer({
    count: items.length,
    getScrollElement: () => userSegmentsContainerRef?.current,
    estimateSize: () => 50,
    overscan: 50,
  });
  const audienceVirtualItems = audienceVirtualiser.getVirtualItems();

  if (!items) {
    return null;
  }
  const firstItem = items[0];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '70%', paddingX: 4 }}>
      <Box>
        {items ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexDirection: 'row' }}>
                <InfoOutlined fontSize="small" />
                <Box>Select the Audiences you want to target:</Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 0,
                  flexDirection: 'column',
                  paddingX: 2,
                  paddingY: 1,
                  width: '50%',
                }}
              >
                <TextField
                  id="searchBar"
                  name="searchBar"
                  label="Search by name"
                  placeholder="Search the list below by name"
                  value={searchValue}
                  onChange={(e) => handleSearch(e)}
                />
                {showAudienceManager ? (
                  <Checkbox
                    name="showPreferencesOnly"
                    label="Display Favourite Audiences Only"
                    onChange={(e, checked) => {
                      setPreferencesOnly(checked);
                      setLocalStorePreferencesOnly(checked);
                    }}
                    checked={preferencesOnly}
                  />
                ) : null}
              </Box>
            </Box>

            <Divider orientation="horizontal" variant="fullWidth" flexItem />
          </>
        ) : null}

        {itemTabs.length > 0 &&
        itemTabs.length === 1 &&
        firstItem &&
        'source' in firstItem &&
        itemTabs[0]?.toLowerCase() !== firstItem?.source.toLowerCase() ? (
          <>
            <Tabs value={tab} onChange={(_, nextTab) => setTab(nextTab)}>
              <Tab
                key="all"
                label="All"
                value="all"
                sx={{ fontSize: '0.7rem', padding: 1, height: 'unset' }}
              />
              {itemTabs.map((tabItem) => {
                return (
                  <Tab
                    key={tabItem}
                    label={tabItem}
                    value={tabItem}
                    sx={{ fontSize: '0.7rem', padding: 1, height: 'unset' }}
                  />
                );
              })}
            </Tabs>
            <Divider orientation="horizontal" variant="fullWidth" flexItem />
          </>
        ) : null}
        {isFetching ? (
          <LinearProgress sx={{ width: '100%' }} data-testid="loading-progress" />
        ) : null}
      </Box>
      <Box
        id="virtualiserContainer"
        sx={{
          width: '100%',
          height: '60vh',
          overflowY: 'auto',
          contain: 'strict',
          padding: '0px 30px',
        }}
        ref={userSegmentsContainerRef}
        data-testid="audience-targeting-list"
      >
        {items.length > 0 ? (
          <Fade in={!isFetching}>
            <Box
              sx={userSegmentDialogStyles.audienceContainer}
              style={{
                width: '100%',
                position: 'relative',
                height: audienceVirtualiser.getTotalSize(),
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  transform: `translateY(${audienceVirtualItems[0]?.start}px)`,
                }}
              >
                {audienceVirtualItems.map((virtualItem) => {
                  const item = items[virtualItem.index];
                  const { key } = virtualItem;

                  if (!item) {
                    return null;
                  }

                  if (item && 'source' in item) {
                    const audienceManagerPath = getAudienceManagerPath(
                      'audience',
                      item.id,
                      item.source,
                    );
                    const isAudienceChecked = !!audienceValue?.some(
                      (selectedAudienceId) => selectedAudienceId === item.id,
                    );
                    // only audiences have property type
                    return (
                      <VirtualListItem
                        key={key}
                        item={item}
                        isChecked={isAudienceChecked}
                        audienceManagerPath={audienceManagerPath}
                        audienceManagerEnabled={showAudienceManager}
                        handleAudienceChange={handleAudienceChange}
                        handleCohortChange={handleCohortChange}
                      />
                    );
                  }

                  const audienceManagerPath = getAudienceManagerPath(
                    'cohort',
                    item.id,
                    item.cohortTypeId,
                  );
                  const isCohortChecked = !!cohortValue?.some(
                    (selectedCohort) => selectedCohort.id === item.id,
                  );
                  return (
                    <VirtualListItem
                      key={key}
                      item={item}
                      isChecked={isCohortChecked}
                      audienceManagerPath={audienceManagerPath}
                      audienceManagerEnabled={showAudienceManager}
                      handleAudienceChange={handleAudienceChange}
                      handleCohortChange={handleCohortChange}
                    />
                  );
                })}
              </div>
            </Box>
          </Fade>
        ) : null}
        {!isFetching && searchValue && items.length === 0 && (
          <Box sx={{ textAlign: 'center', padding: '20px' }}>
            There were no audiences found based on your search term
          </Box>
        )}
        {!isFetching && !searchValue && preferencesOnly && audienceVirtualItems.length === 0 && (
          <Box sx={{ ...virtualListItemStyle, justifyContent: 'center', border: '0px' }}>
            No preferred audiences have been selected
          </Box>
        )}
      </Box>
    </Box>
  );
}

type CohortSelectInputProps = {
  isValid: boolean;
  invalid?: boolean;
  handleOpenDialog: () => void;
  inputRef: RefCallBack;
  error?: FieldError | undefined;
  value: Array<CohortType>;
  id: string;
  disabled?: boolean;
};

export function CohortSelectInput({
  isValid,
  invalid = false,
  handleOpenDialog,
  inputRef,
  error = undefined,
  value,
  id,
  disabled = false,
}: CohortSelectInputProps) {
  return (
    <TextFieldLookalike
      label="Audience Targeting"
      select
      isValid={isValid}
      error={invalid}
      errorMessage={error?.message || ' '}
      disabled={disabled}
      onClick={handleOpenDialog}
      inputRef={inputRef}
      id={id}
    >
      {value.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {value.map((cohortType) => {
            const displayItems = [...cohortType.cohorts, ...cohortType.audiences]
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((cohort) => cohort.name);

            return (
              <SingleTypeList
                key={cohortType.id}
                label={
                  cohortType.name === cohortType.ownerTitleText
                    ? cohortType.name
                    : `${cohortType.name} ${cohortType.ownerTitleText}`
                }
                items={displayItems}
                variant="primary"
                columnCount={3}
              />
            );
          })}
        </Box>
      )}
    </TextFieldLookalike>
  );
}
